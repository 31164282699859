import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { getAuth } from 'firebase/auth'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { ArrowRightRounded, DirectionsWalkRounded, ErrorRounded } from '@mui/icons-material'
import { Box, Button, CircularProgress, Skeleton, Typography } from '@mui/material'
import markerAIcon from '../../img/markerAIcon.png'
import markerBIcon from '../../img/markerBIcon.png'
import occupancyFew from '../../img/occupancyFew.svg'
import occupancyVeryFew from '../../img/occupancyVeryFew.svg'
import occupancyMany from '../../img/occupancyMany.svg'
import occupancyFull from '../../img/occupancyFull.svg'
import RealTimeIcon from '../../img/real-time-icon.svg'
import {
  FromToIcon,
  HeadSign,
  InfoData,
  InfoGroup,
  InfoItem,
  LineContainer,
  LineIcon,
  LineName,
  Number,
  OcupancyContainer,
  OcupancyIcon,
  RealTimeContainer,
  RealTimeIconImg,
  Schedule,
  ScheduleDate,
  Span,
  TripDetails,
  TripSteps,
  WalkStep,
  WalkValue,
  WarningLabel,
  WarningText
} from './styles'
import { getUrlRealTimeByCity } from '../../utils'
import { getArrivalsRealTime } from '../../db/getArrivalsRealTime'
import { getTripUpdates } from '../../db/getTripUpdates'
import useQuery from '../../hooks/useQuery'
import { REACT_APP_SHOW_RECOMMENDED_ROUTES_FARES, REACT_APP_SHOW_SEARCH_RESULT_TRIP_DURATION } from '../../constants/config'
import { theme } from '../../theme/theme'

const ResultItem = ({ trip, onClickFunction, border, showOccupancyLevel }) => {
  const auth = getAuth()
  const user = auth.currentUser
  const { t } = useTranslation()
  const query = useQuery()

  const [occupancy, setOccupancy] = useState({})
  const [realTime, setRealtime] = useState()
  const [schedule, setSchedule] = useState()
  const [legNoWalk, setLegNoWalk] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const mode = useSelector(state => state?.ui.mode)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  /**
   * Get minutes from now
   * @param {number} realtime
   * @returns {number}
   */
  const getMinutesFromNow = (realtime) => {
    const now = moment()
    const arrival = moment(realtime)
    const diff = arrival.diff(now, 'minutes')

    if (diff > 0) {
      return diff
    } else {
      return false
    }
  }

  useEffect(() => {
    // check if realtime exists in the first no-walk leg

    if (!trip) return

    const firstLegNoWalk = trip?.legs?.find(item => item.mode !== 'WALK')

    if (!firstLegNoWalk) {
      return setIsLoading(false)
    }

    setLegNoWalk(firstLegNoWalk)

    setSchedule(moment(firstLegNoWalk?.startTime).format('HH:mm'))

    if (firstLegNoWalk?.realTime && firstLegNoWalk?.realtimeArrival > 0 && !JSON.parse(query.get('timestamp'))) {
      const minutes = Math.floor(firstLegNoWalk?.realtimeArrival / 60000)
      setIsLoading(false)
      return setRealtime({ minutes })
    }

    if (!cityConfig?.city_id) {
      return console.error('Error getting trip realtime. No cityConfig provided.')
    }

    const interval = setInterval(() => getSearchResultsRealtime(firstLegNoWalk), 30000)
    getSearchResultsRealtime(firstLegNoWalk)

    return () => {
      clearInterval(interval)
    }
  }, [])

  /** getSearchResultsRealtime
   * @description sets the realtime of the current itinerary
   * @param {object} firstLegNoWalk - first leg mode no "walk" of current itinerary
   */

  const getSearchResultsRealtime = (firstLegNoWalk) => {
    if (!firstLegNoWalk || JSON.parse(query.get('timestamp'))) {
      return setIsLoading(false)
    }

    const { transportTypeId } = firstLegNoWalk

    const [busUrl, subwayUrl] = getUrlRealTimeByCity(cityConfig?.city_id, {
      stop_code: firstLegNoWalk?.from?.stopId,
      route_id: firstLegNoWalk?.routeId
    })

    const controller = new AbortController()
    const signal = controller.signal

    setTimeout(() => {
      controller.abort()
    }, 5000)

    if (cityConfig.config?.realtime_hub_enabled) {
      getTripUpdates(cityConfig.city_id, firstLegNoWalk?.from?.stopId, signal)
        .then(response => {
          const realtimeResult = response?.result?.find(item => item.routeId === firstLegNoWalk?.routeId && item.headsign === firstLegNoWalk.headsign)

          const minutes = realtimeResult?.realtime && realtimeResult?.realtimeArrival > 0 ? Math.floor(realtimeResult?.realtimeArrival / 60000) : null

          if (minutes) {
            setRealtime({ minutes })
          } else {
            setRealtime(null)
            setSchedule(realtimeResult?.scheduledArrival
              ? moment(realtimeResult.scheduledArrival).format('HH:mm')
              : moment(firstLegNoWalk?.startTime).format('HH:mm')
            )
          }

          setIsLoading(false)
        })
        .catch((e) => {
          console.error(e)
          setRealtime(null)
          setIsLoading(false)
          setSchedule(moment(firstLegNoWalk?.startTime).format('HH:mm'))
        })
    } else if (cityConfig.config?.realtime_arrivals_enabled) {
      const params = {
        city_id: cityConfig.city_id,
        url: cityConfig.city_id === 21 && transportTypeId === 3 ? subwayUrl : busUrl,
        api_buenos_aires: cityConfig.city_id === 21
      }

      if (cityConfig.city_id === 22) { // Santiago de Chile, Chile
        if (transportTypeId !== 1) {
          return setIsLoading(false)
        }
        user.getIdToken()
          .then(token => {
            fetch(busUrl, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
                'variant-id': process.env.REACT_APP_VARIANT_ID
              },
              signal
            })
              .then(response => {
                if (response.ok) {
                  return response.json()
                } else {
                  throw new Error('Error getting realtime')
                }
              })
              .then(response => {
                const result = response?.result?.entities?.item?.filter(item => item?.horaprediccionbus1)
                if (result?.length > 0) {
                  setRealtime({ minutes: result[0]?.horaprediccionbus1?.replace(' min.', "'") })
                  setIsLoading(false)
                } else {
                  getTripUpdates(cityConfig.city_id, firstLegNoWalk?.from?.stopId)
                    .then(response => {
                      const realtimeResult = response?.result?.find(item => item.routeId === firstLegNoWalk?.routeId && item.headsign === firstLegNoWalk.headsign)
                      setIsLoading(false)

                      setRealtime(null)
                      setSchedule(realtimeResult?.scheduledArrival
                        ? moment(realtimeResult.scheduledArrival).format('HH:mm')
                        : moment(firstLegNoWalk?.startTime).format('HH:mm')
                      )
                    })
                    .catch((e) => {
                      console.error(e)
                      setIsLoading(false)
                      setRealtime(null)
                      setSchedule(moment(firstLegNoWalk?.startTime).format('HH:mm'))
                    })
                }
              })
              .catch(error => {
                console.error(error)
                setRealtime(null)
                setIsLoading(false)
                setSchedule(moment(firstLegNoWalk?.startTime).format('HH:mm'))
              })
          })
      } else {
        getArrivalsRealTime(params, signal)
          .then(response => {
            let minutes = false
            let arrivalClosest = []
            let arrivalFiltered = []

            switch (cityConfig.city_id) {
              case 30: // Montevideo, Uruguay

                // Validations:
                // - If response (realtime) includes "mensaje" or "No existe" words or if transport type id is different to 1 (isn't a bus transport type), the execution of the function is stopped

                if (JSON.stringify(response).includes('mensaje') || JSON.stringify(response).includes('No existe') || transportTypeId !== 1) {
                  return setIsLoading(false)
                }

                // use .sort method to order array by time
                arrivalClosest = response
                  ?.filter(item => item?.variante.toString() === firstLegNoWalk?.shapeId && item?.real)
                  ?.sort((a, b) => (a.time > b.time) ? 1 : ((b.time > a.time) ? -1 : 0))[0]

                if (!arrivalClosest) {
                  return setIsLoading(false)
                }

                minutes = getMinutesFromNow(arrivalClosest.time)

                break // Montevideo, Uruguay
              case 29: // Mendoza, Argentina

                if (response?.code !== 200) {
                  return setIsLoading(false)
                }

                arrivalClosest = response?.data?.entry.arrivalsAndDepartures
                  ?.filter(arrival => arrival?.predicted && arrival?.routeId?.toString() === firstLegNoWalk?.routeId?.toString())
                  ?.sort((a, b) => (a?.scheduledArrivalTime > b?.scheduledArrivalTime) ? 1 : ((b?.scheduledArrivalTime > a?.scheduledArrivalTime) ? -1 : 0))[0]

                if (!arrivalClosest) {
                  return setIsLoading(false)
                }

                minutes = getMinutesFromNow(arrivalClosest?.scheduledArrivalTime)

                break // Mendoza, Argentina
              case 21: // Buenos Aires, Argentina

                if (transportTypeId === 1) { // Bus
                  if (response.code !== 200) {
                    return setIsLoading(false)
                  }

                  arrivalFiltered = response?.data?.entry?.arrivalsAndDepartures?.filter((arrival) => {
                    const routeName = arrival?.routeShortName || arrival?.routeLongName
                    const tripRouteName = firstLegNoWalk?.routeShortName || firstLegNoWalk?.routeLongName
                    return arrival?.predicted === true && routeName === tripRouteName
                  })

                  if (arrivalFiltered.length === 0) {
                    return setIsLoading(false)
                  }

                  arrivalClosest = _.orderBy(arrivalFiltered, ['predictedArrivalTime'], ['asc'])?.map(item => {
                    return getMinutesFromNow(item?.predictedArrivalTime)
                  })?.filter(item => item !== false)

                  minutes = arrivalClosest?.length > 0 ? arrivalClosest[0] : false
                } else if (firstLegNoWalk?.transportTypeId === 3 && firstLegNoWalk?.from?.stopId && firstLegNoWalk?.routeId) {
                  const stopId = firstLegNoWalk?.from?.stopId?.includes(':') ? firstLegNoWalk?.from?.stopId?.split(':')[1] : firstLegNoWalk?.from?.stopId
                  arrivalFiltered = response?.Entity?.find((entity) => entity?.Linea?.Estaciones?.some(station => station?.stop_id === stopId))
                  if (!arrivalFiltered) {
                    return setIsLoading(false)
                  }

                  minutes = getMinutesFromNow(arrivalFiltered.Linea?.Estaciones?.find(station => station.stop_id === stopId)?.arrival?.time * 1000)
                } else {
                  setIsLoading(false)
                }

                break // Buenos Aires, Argentina
            }

            if (minutes) {
              setRealtime({ minutes })
              setIsLoading(false)
            } else {
              getTripUpdates(cityConfig.city_id, firstLegNoWalk?.from?.stopId)
                .then(response => {
                  const realtimeResult = response?.result?.find(item => item.routeId === firstLegNoWalk?.routeId && item.headsign === firstLegNoWalk.headsign)

                  setRealtime(null)
                  setSchedule(realtimeResult?.scheduledArrival
                    ? moment(realtimeResult.scheduledArrival).format('HH:mm')
                    : moment(firstLegNoWalk?.startTime).format('HH:mm')
                  )
                  setIsLoading(false)
                })
                .catch((e) => {
                  console.error(e)
                  setRealtime(null)
                  setIsLoading(false)
                  setSchedule(moment(firstLegNoWalk?.startTime).format('HH:mm'))
                })
            }
          })
          .catch(err => {
            console.error(err)
            setRealtime(null)
            setIsLoading(false)
            setSchedule(moment(firstLegNoWalk?.startTime).format('HH:mm'))
          })
      }
    } else {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const result = crowdedAverage()
    setOccupancy(result)
  }, [])

  /**
   * Get the average crowded of the leg
   * @returns {object}
   */

  const crowdedAverage = () => {
    const value = trip?.legs?.filter(item => item?.mode !== 'WALK')[0]?.crowdedAverage

    switch (value) {
      case 0: {
        return { label: t('trip_share.occupancy_item_title_1'), icon: occupancyVeryFew }
      }
      case 1: {
        return { label: t('trip_share.occupancy_item_title_2'), icon: occupancyFew }
      }
      case 2: {
        return { label: t('trip_share.occupancy_item_title_3'), icon: occupancyMany }
      }
      case 3: {
        return { label: t('trip_share.occupancy_item_title_4'), icon: occupancyFull }
      }
      default: {
        return {}
      }
    }
  }

  return (
    <Button
      disabled={isLoading}
      border={border}
      onClick={
        onClickFunction
          ? () => onClickFunction({
              ...trip,
              arrivalRealtime: realTime?.minutes
            })
          : () => {
            }
      }
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: '100%',
        boxSizing: 'border-box',
        borderBottom: border ? '1px solid #E8EDEE' : 'none',
        padding: '16px 6px 16px 23px',
        color: '#000',
        '&:hover': {
          backgroundColor: '#F6F8F9'
        },
        borderRadius: '8px',
        '@media (max-width:480px)': {
          padding: '16px'
        },
        '&:focus': {
          border: `${!mode ? `1px solid ${theme.palette.primary.main}` : 'none'}`
        }
      }}
    >
      <TripDetails>
        <InfoGroup>
          {REACT_APP_SHOW_RECOMMENDED_ROUTES_FARES && (trip?.fare?.cents / 10 ** trip?.fare?.default_fraction_digits).toFixed(2) > 0 &&
            <InfoItem>
              {trip
                ? (
                  <>
                    <Typography sx={{
                      margin: '0 0 4px 0',
                      color: '#000',
                      fontSize: '14px',
                      fontWeight: '400',
                      '@media (max-width:480px)': {
                        fontSize: '10px',
                        fontWeight: 'bold'
                      }
                    }}
                    >
                      {t('trip_share.search_result_fare_title')}
                    </Typography>
                    <InfoData>{cityConfig?.country?.coinSymbol} {(trip?.fare?.cents / 10 ** trip?.fare?.default_fraction_digits).toFixed(2)}</InfoData>
                  </>
                  )
                : (
                  <>
                    <Skeleton width='40px' height='25px' variant='text' />
                    <Skeleton width='40px' height='36px' variant='text' />
                  </>
                  )}

            </InfoItem>}
          <InfoItem>
            {trip
              ? (
                <>
                  <Typography
                    sx={{
                      margin: '0 0 4px 0',
                      color: '#000',
                      fontSize: '14px',
                      fontWeight: '400',
                      '@media (max-width:480px)': {
                        fontSize: '10px',
                        fontWeight: 'bold'
                      }
                    }}
                  >{t('trip_share.search_result_arrival_title')}</Typography>
                  <InfoData>{moment(trip?.endTime).format('HH:mm')}</InfoData>
                </>
                )
              : (
                <>
                  <Skeleton width='40px' height='25px' variant='text' />
                  <Skeleton width='40px' height='36px' variant='text' />
                </>
                )}

          </InfoItem>
          <InfoItem
            style={{ position: 'relative' }}
          >
            {showOccupancyLevel && occupancy?.icon && occupancy?.label &&
              <>
                <Typography
                  sx={{
                    margin: '0 0 4px 0',
                    color: '#000',
                    fontSize: '14px',
                    fontWeight: '400',
                    '@media (max-width:480px)': {
                      fontSize: '10px',
                      fontWeight: 'bold'
                    }
                  }}
                >
                  {t('trip_share.passengers_on_board_title')}
                </Typography>
                <OcupancyContainer>
                  <OcupancyIcon src={occupancy.icon} />
                  <InfoData $mode={mode}>{occupancy.label}</InfoData>
                </OcupancyContainer>
              </>}
          </InfoItem>
          {REACT_APP_SHOW_SEARCH_RESULT_TRIP_DURATION &&
            <InfoItem>
              <ScheduleOrRealTimeMini schedule={schedule} realTime={realTime} />
            </InfoItem>}

        </InfoGroup>
        {isLoading
          ? (
            <Box sx={{
              padding: '3px'
            }}
            >
              <CircularProgress size='25px' />
            </Box>
            )
          : (
            <>
              {!REACT_APP_SHOW_SEARCH_RESULT_TRIP_DURATION && <ScheduleOrRealTime schedule={schedule} realTime={realTime} />}
              {REACT_APP_SHOW_SEARCH_RESULT_TRIP_DURATION && trip?.duration && <TripDuration duration={trip?.duration} />}
            </>
            )}

      </TripDetails>

      <TripSteps $mode={mode}>

        {trip?.legs?.length > 0
          ? (
            <>
              <FromToIcon src={markerAIcon} />
              <ArrowRightRounded sx={{ width: '15px', color: '#BCBEC0' }} />
              {trip?.legs?.map((item, index) => {
                if (item.mode === 'WALK') {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                      key={index}
                    >
                      <WalkStep>
                        <DirectionsWalkRounded sx={{ width: '20px', color: '#BCBEC0' }} />
                        <WalkValue>{parseInt(item.distance / 100)}</WalkValue>
                      </WalkStep>
                      <ArrowRightRounded sx={{ width: '15px', color: '#BCBEC0' }} />
                    </div>
                  )
                } else {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                      key={index}
                    >
                      <LineContainer color={item?.routeColor}>
                        <LineIcon
                          src={cityConfig?.transport_types?.filter(transport => transport.transport_type_id === item?.transportTypeId)[0]?.icon}
                        />
                        <LineName>{item?.routeShortName || item?.routeLongName}</LineName>
                      </LineContainer>
                      <ArrowRightRounded sx={{ width: '15px', color: '#BCBEC0' }} />
                    </Box>
                  )
                }
              })}
              <FromToIcon src={markerBIcon} />
            </>
            )
          : (
            <Skeleton width='250px' height='30px' variant='rectangular' />
            )}
      </TripSteps>

      {legNoWalk
        ? <HeadSign $mode={mode}>{legNoWalk?.headsign}</HeadSign>
        : <Skeleton width='80%' height='28px' variant='text' />}

      {legNoWalk?.from?.affected &&
        <WarningLabel>
          <ErrorRounded sx={{ width: '18px', color: '#FF4B55', marginRight: '8px' }} />
          <WarningText>Hay reportes en esta línea</WarningText>
        </WarningLabel>}
    </Button>
  )
}

export default ResultItem

const ScheduleOrRealTime = ({ schedule, realTime }) => {
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const { t } = useTranslation()
  const mode = useSelector(state => state?.ui.mode)

  return (
    realTime
      ? (
        <RealTimeContainer>
          <RealTimeIconImg top={cityConfig?.city_id ? '20px' : '5px'} src={RealTimeIcon} />
          <Number
            fontSize={cityConfig?.city_id === 22 ? '14px' : '24px'}
          >
            {realTime.minutes}
          </Number>
          {cityConfig?.city_id !== 22 && <Span>min</Span>}
        </RealTimeContainer>
        )
      : (
        <Schedule>
          {schedule
            ? (
              <>
                <Typography sx={{
                  margin: '0 0 4px 0',
                  color: '#000',
                  fontSize: '14px',
                  fontWeight: '400',
                  '@media (max-width:480px)': {
                    fontSize: '10px',
                    fontWeight: 'bold'
                  }
                }}
                >
                  {t('stop.scheduled')}
                </Typography>
                <ScheduleDate $mode={mode}>{schedule}</ScheduleDate>
              </>
              )
            : (
              <>
                <Skeleton width='20px' height='25px' variant='text' />
                <Skeleton width='50px' height='36px' variant='text' />
              </>
              )}

        </Schedule>
        )
  )
}

const TripDuration = ({ duration }) => {
  const { t } = useTranslation()
  return (
    <Box>
      <Typography sx={{
        margin: '0 0 4px 0',
        color: '#000',
        fontSize: '14px',
        fontWeight: '400',
        '@media (max-width:480px)': {
          fontSize: '10px',
          fontWeight: 'bold'
        }
      }}
      >
        {t('trip_share.trip_duration_title')}
      </Typography>
      <Typography>{(duration / 60).toFixed(0)} min.</Typography>
    </Box>
  )
}

const ScheduleOrRealTimeMini = ({ schedule, realTime }) => {
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const { t } = useTranslation()

  return (
    realTime
      ? (
        <RealTimeContainer>
          <RealTimeIconImg top={cityConfig?.city_id ? '20px' : '5px'} src={RealTimeIcon} />
          <Number
            fontSize='14px'
          >
            {realTime.minutes}
          </Number>
          {cityConfig?.city_id !== 22 && <Span>min</Span>}
        </RealTimeContainer>
        )
      : (
        <Schedule>
          {schedule
            ? (
              <>
                <Typography sx={{
                  margin: '0 0 4px 0',
                  color: '#000',
                  fontSize: '14px',
                  fontWeight: '400',
                  '@media (max-width:480px)': {
                    fontSize: '10px',
                    fontWeight: 'bold'
                  }
                }}
                >
                  {t('stop.scheduled')}
                </Typography>
                <InfoData>{schedule}</InfoData>
              </>
              )
            : (
              <>
                <Skeleton width='20px' height='25px' variant='text' />
                <Skeleton width='50px' height='36px' variant='text' />
              </>
              )}

        </Schedule>
        )
  )
}
