import 'firebase/compat/auth'
import { types } from '../types/types'
import _ from 'lodash'
// import amplitude from 'amplitude-js'
import { analytics, app, db, messaging, setUserProperties } from '../firebase/firebase-config'
import { handleOpenAlert, setAppConfiguration } from './ui'
import { getLocalFavLines } from '../db/getLocalFavLines'
import { getUserLines } from '../helpers/getUserLines'
import { setLocalFavLines } from '../hooks/setLocalFavLines'
import {
  signInWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInAnonymously,
  linkWithRedirect,
  updateProfile,
  EmailAuthProvider,
  linkWithCredential,
  signInWithCustomToken
} from 'firebase/auth'
import { getUser } from '../helpers/getUser'
import { addDoc, collection, doc, setDoc, updateDoc } from 'firebase/firestore'
import { getUserSavedPlaces } from '../helpers/getUserSavedPlaces'
import { getUserPlaces } from '../helpers/getUserPlaces'
import { getToken, isSupported } from 'firebase/messaging'
import i18next from '../i18n'
import { getLocalCityConfig } from '../db/getLocalCityConfig'
import { REACT_APP_PROJECT_ID, REACT_APP_FCM_V_API_KEY, REACT_APP_VARIANT_ID, REACT_APP_FIREBASE_MEASUREMENT_ID } from '../constants/config'
import { sendEmailVerification } from '../db/sendEmailVerification'
import { subscribeToTopic } from '../db/subscribeToTopic'
import { deleteAccount } from '../db/deleteAccount'
import { createUser } from '../db/createUser'
import { setUserId } from 'firebase/analytics'

export const setLinesFav = (list) => ({
  type: types.linesFav,
  payload: list
})

export const setTransportationType = (type) => ({
  type: types.transportationType,
  payload: type
})

export const setLineSearchResult = (result) => ({
  type: types.lineSearchResult,
  payload: result
})

export const setUserPosition = (position) => ({
  type: types.userPosition,
  payload: position
})

export const setUserAuth = (auth) => ({
  type: types.auth,
  payload: auth
})

export const setUserData = (data) => ({
  type: types.userData,
  payload: data
})
export const setFcmToken = (token) => ({
  type: types.fcmToken,
  payload: token
})

export const setPlace = (place) => ({
  type: types.savedPlaces,
  payload: place
})

export const startLogin = () => {
  return async (dispatch, getState) => {
    const auth = getAuth()

    let {
      user: { fcmToken, savedPlaces },
      ui: { cityConfig }
    } = getState()

    const localCityConfig = getLocalCityConfig()

    if (!cityConfig && localCityConfig) {
      cityConfig = localCityConfig
    }

    const searchParams = new URLSearchParams(window.location.search)

    if (searchParams.get('custom_token')) {
      signInWithCustomToken(auth, searchParams.get('custom_token'))
      // .then((userCredential) => {
      //   const user = userCredential.user
      // })
      // .catch((error) => {
      //   console.error('error', error)
      // })
    }

    onAuthStateChanged(auth, async (user) => {
      setUserProperties({
        variant_id: REACT_APP_VARIANT_ID.toString()
      })
      if (user) {
        setUserProperties({
          user_id: user.uid
        })
        setUserId(analytics, user.uid)

        // Initialize gtag with Firebase Analytics
        window.dataLayer = window.dataLayer || []
        function gtag() { window.dataLayer.push(arguments) }
        gtag('js', new Date())
        gtag('config', REACT_APP_FIREBASE_MEASUREMENT_ID)

        sessionStorage.setItem('uid', user.uid)

        user
          .getIdToken()
          .then((token) => {
            sessionStorage.setItem('token', token)
          })
          .catch((error) => {
            console.error(error)
          })

        if (user?.isAnonymous) {
          // amplitude.getInstance().setUserId(null)
          dispatch(setUserData(undefined))
          dispatch(setUserContribution(undefined))
          dispatch(setUserAuth(user))
          dispatch(setAppConfiguration())

          setUserProperties({
            logged: false.toString()
          })

          const lines = getLocalFavLines()
          dispatch(setLinesFav(lines))
        } else {
          // amplitude.getInstance().setUserId(user.uid)
          let userData = null

          try {
            userData = await getUser(user?.uid)
          } catch (e) {
            console.error(e)
          }

          const userNewData = {}
          if (userData) {
            if (!userData.project_id) {
              userNewData.project_id = REACT_APP_PROJECT_ID
            }

            if (!userData.variant_id) {
              userNewData.variant_id = REACT_APP_VARIANT_ID
            }

            if (!userData.display_name && user.displayName) {
              userNewData.display_name = user.displayName
            } else if (!userData?.display_name && user?.providerData[0]?.displayName) {
              userNewData.display_name = user.providerData[0]?.displayName
            }

            if (!userData?.email && user?.email) {
              userNewData.email = user.email
            }

            if (userNewData) {
              try {
                const docRef = doc(db, 'users', user?.uid)
                await setDoc(
                  docRef,
                  userNewData,
                  { merge: true }
                )
              } catch (e) {
                console.error(e)
              }
            }
          }

          dispatch(
            setUserData({
              uid: user?.uid,
              photo_url: user?.photoURL || user.photoUrl || user.providerData[0]?.photoURL,
              display_name: user?.displayName || user.display_name || user.providerData[0]?.displayName,
              email: user?.email,
              project_id: REACT_APP_PROJECT_ID
            })
          )

          dispatch(setUserAuth(user))

          setUserProperties({
            display_name:
                userData && userData?.display_name
                  ? userData?.display_name.toString()
                  : 'null',
            fcm_token: fcmToken || userData?.fcm_token?.toString(),
            level: userData?.level?.toString(),
            nickname: userData?.nickname?.toString(),
            user_birthday_timestamp: userData
              ? userData?.birthday?.long_value?.toString()
              : 'null',
            user_gender: userData ? userData?.gender?.toString() : 'null',
            user_level: userData?.level?.toString(),
            user_score: userData?.score?.toString(),
            newsletter_subscribed: userData?.newsletter_subscribed?.toString(),
            project_id: REACT_APP_PROJECT_ID.toString(),
            variant_id: REACT_APP_VARIANT_ID.toString()
          })

          if (fcmToken) {
            const userRef = doc(db, 'users', user.uid)
            await updateDoc(userRef, { fcm_token_web: fcmToken })
          }

          const userLinesRef = collection(db, `users/${user.uid}/lines`)

          const userLinesFav = await getUserLines() // User lines fav (Firestore)
          const localLines = getLocalFavLines()

          const localLinesFav = localLines?.length ? localLines : []

          const uniqueLines = _.differenceBy(
            localLinesFav,
            userLinesFav,
            'line_id'
          )
          const uniqueLinesFromDb = _.differenceBy(
            userLinesFav,
            localLinesFav,
            'line_id'
          )

          if (uniqueLines?.length && uniqueLinesFromDb?.length) {
            const allLines = [...localLinesFav, ...userLinesFav]
            const currentLinesFav = _.uniqBy(allLines, 'line_id')

            const newLinesDocs = uniqueLines.filter(
              (line) =>
                !userLinesFav?.some(
                  (current) => current?.line_id === line?.line_id
                )
            )

            await Promise.all(
              newLinesDocs.map(async (newDoc) => {
                return await addDoc(userLinesRef, newDoc)
              })
            )

            setLocalFavLines(currentLinesFav)
            dispatch(setLinesFav(currentLinesFav))
          } else {
            // if there are new lines in local storage, add them to db
            if (uniqueLines?.length) {
              await Promise.all(
                uniqueLines.map(async (newDoc) => {
                  return await addDoc(userLinesRef, newDoc)
                })
              )
            }

            // if there are new lines in db, add them to local storage
            if (uniqueLinesFromDb?.length) {
              setLocalFavLines([...uniqueLinesFromDb, ...localLinesFav])
              dispatch(setLinesFav([...uniqueLinesFromDb, ...localLinesFav]))
            }
          }

          const userSavedPlaces = await getUserSavedPlaces() // User saved places (Firestore)
          const userPlaces = await getUserPlaces()

          if (savedPlaces) {
            const arrFiltered = _.uniqBy(
              [...savedPlaces, ...userSavedPlaces, ...userPlaces],
              'id'
            )
            dispatch(setPlace(arrFiltered))
          } else {
            const arrFiltered = _.uniqBy(
              [...userSavedPlaces, ...userPlaces],
              'id'
            )
            dispatch(setPlace(arrFiltered))
          }

          dispatch(setAppConfiguration())
          setUserProperties({
            logged: true.toString()
          })
        }
      } else {
        // User is signed out
        signInAnonymously(auth)
          .catch((e) => console.error(e))
      }

      isSupported()
        .then((isSupported) => {
          if (isSupported) {
            getToken(messaging, { vapidKey: REACT_APP_FCM_V_API_KEY })
            const topics = [
                `city_${cityConfig?.city_id}`,
                `web_city_${cityConfig?.city_id}`,
                `city_${cityConfig?.city_id}_variant_${REACT_APP_VARIANT_ID}`,
                `web_city_${cityConfig?.city_id}_variant_${REACT_APP_VARIANT_ID}`,
                `alerts_${cityConfig?.city_id}`,
                `web_alerts_${cityConfig?.city_id}`,
                `ack_city_${cityConfig?.city_id}`,
                `web_ack_city_${cityConfig?.city_id}`,
                `alerts_${cityConfig?.city_id}_variant_${REACT_APP_VARIANT_ID}`,
                `web_alerts_${cityConfig?.city_id}_variant_${REACT_APP_VARIANT_ID}`
            ]

            getToken(messaging, { vapidKey: REACT_APP_FCM_V_API_KEY })
              .then(async (currentToken) => {
                if (currentToken) {
                  dispatch(setFcmToken(currentToken))
                  await Promise.all(topics.map(topic => subscribeToTopic(topic, currentToken)))
                } else {
                  // Show permission request UI
                  console.error(
                    'No registration token available. Request permission to generate one.'
                  )
                  // ...
                }
              })
              .catch((err) => {
                console.error(
                  'An error occurred while retrieving messaging token. ',
                  err
                )
                // ...
              })
          } else {
            console.error('messaging not supported')
          }
        })
        .catch((e) => {
          console.error('Firebase messaging is not supported in this browser', e)
        })
    })
  }
}

export const setUserSearchFilters = (filters) => ({
  type: types.searchFilters,
  payload: filters
})

export const setUserContribution = (contribution) => ({
  type: types.userContribution,
  payload: contribution
})

export const startLoginWithProvider = (provider, navigate, setLoading = {}) => {
  return async () => {
    const auth = getAuth(app)

    linkWithRedirect(auth.currentUser, provider)
  }
}

/**
 * Call firebase's signInWithEmailAndPassword method, sign in sent by parameter, and redirect the user to the home page.
 * In the event that the method fails, an alert is displayed with an error message.
 * @param {String} email - the user's email
 * @param {String} password - the user's password
 * @param {function} setCreateAccount - function to set the create account flag
 * @param {function} setErrorMessage - function to set the error message
 * @param navigate
 * @param setLoading
 * @returns {(function(*, *): void)|*}
 */

export const startLoginWithEmailAndPassword = (
  email,
  password,
  setCreateAccount,
  setErrorMessage,
  navigate,
  setLoading
) => {
  return async (dispatch) => {
    const auth = getAuth()
    const anonUserToken = await auth.currentUser?.getIdToken()

    signInWithEmailAndPassword(auth, email, password)
      .then(async (data) => {
        // remove anonymous user

        await deleteAccount(anonUserToken)

        const user = data.user

        // create/update firestore user document
        const docRef = doc(db, 'users', user.uid)

        await setDoc(
          docRef,
          {
            email,
            display_name: user?.display_name || user?.displayName || null,
            variant_id: process.env.REACT_APP_VARIANT_ID
          },
          { merge: true }
        )
          .then(() => {
            getUser(user?.uid)
              .then((userData) =>
                dispatch(setUserData({ ...userData, uid: userData.id }))
              )
              .catch((e) => console.error(e))
          })
          .catch((e) => console.error(e))
        setLoading(false)
        navigate('/')
      })
      .catch((error) => {
        setLoading(false)
        const errorCode = error.code
        const errorMessage = error.message
        console.error('error', errorMessage)
        if (errorCode === 'auth/user-not-found') {
          setCreateAccount(true)
        } else if (errorCode === 'auth/wrong-password') {
          setErrorMessage('La contraseña es incorrecta.')
        }
      })
  }
}

/**
 * Call the createUserWithEmailAndPassword method of firebase, create an account with the email and password
 * sent by parameter and redirect the user to the account verification page.
 * In the event that the method fails, an alert is displayed with an error message.
 * @param {String} email
 * @param {String} password
 * @param {String} name
 * @param navigate
 * @param setLoading
 * @returns {(function(*): void)|*}
 */

export const createEmailAndPasswordUser = (
  email,
  password,
  name,
  navigate,
  setLoading
) => {
  return (dispatch) => {
    const auth = getAuth()

    const credential = EmailAuthProvider.credential(email, password)

    linkWithCredential(auth.currentUser, credential)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user

        // update user profile auth
        await updateProfile(user, { displayName: name }).catch((e) =>
          console.error('update profile error', e)
        )

        try {
          await createUser()
        } catch (e) {
          console.error('create user error', e)
          // TODO: logout user
        }

        await sendEmailVerification()
          .catch((error) => {
            console.error('error', error)
          })

        dispatch(
          setUserData({
            uid: user.id,
            ...user,
            project_id: REACT_APP_PROJECT_ID
          })
        )
        // redirect to home
        setLoading(false)
        navigate('/')
      })
      .catch((error) => {
        setLoading(false)
        console.error(error)
        dispatch(
          handleOpenAlert({
            open: true,
            severity: 'error',
            duration: 3000,
            title: i18next.t('login.failure_login_title'),
            message: i18next.t('login.failure_login_message')
          })
        )
      })
  }
}

export const setUserTicketRewardStats = (stats) => ({
  type: types.userTicketRewardStats,
  payload: stats
})
