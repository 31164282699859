import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  enabledAlertsAndNewsComponent,
  handleRecharge,
  setCurrentNavbarPosition,
  setOpenCountryAlert,
  setShowFilters,
  verifyCityInfo
} from '../../actions/ui'
import { getLocalUserUid } from '../../db/getLocalUserUid'
import { logEvent } from '../../firebase/firebase-config'
import { useTranslation } from 'react-i18next'
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material'
import { AttachMoneyRounded, CallSplit, InfoRounded, MapRounded, NotificationsRounded } from '@mui/icons-material'
import { theme } from '../../theme/theme'
import { SmartBottomBanner } from '../../uicomponents/ui'
import ConfirmationNumberRoundedIcon from '@mui/icons-material/ConfirmationNumberRounded'
import { REACT_APP_TICKETS_REWARDS_PROGRAM } from '../../constants/config'

const NavBar = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const uid = getLocalUserUid()

  const auth = useSelector(state => state.user.auth)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const { alertsAndNews, navbarPosition } = useSelector(state => state?.ui?.components)
  const userData = useSelector(state => state?.user?.userData)
  const openBottomSmartBanner = useSelector(state => state?.ui?.components.openBottomSmartBanner)
  const filters = useSelector(state => state?.ui?.components?.mapFilters)
  const isIOSApp = useSelector(state => state?.ui?.isIOSApp)

  const handleReturnHome = () => {
    dispatch(enabledAlertsAndNewsComponent({ enabled: false, current: undefined }))

    if (cityConfig) {
      navigate('/')
    } else {
      dispatch(setOpenCountryAlert(true))
    }
  }

  const handleCityInfo = () => {
    dispatch(enabledAlertsAndNewsComponent({ enabled: false, current: undefined }))

    if (cityConfig) {
      const eventParams = {
        lat: userPosition ? userPosition.lat : null,
        lng: userPosition ? userPosition.lng : null,
        city_id: cityConfig?.city_id.toString(),
        user_id: sessionStorage.getItem('uid'),
        os: 'web',
        user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
        user_gender: userData?.gender || null // String
      }

      logEvent('city_info_button', eventParams)

      navigate('/city_info')
      dispatch(verifyCityInfo())
    } else {
      dispatch(setOpenCountryAlert(true))
    }
  }

  const handleAlerts = () => {
    if (cityConfig) {
      dispatch(setShowFilters(false))

      logEvent('alerts', {
        lat: userPosition ? userPosition.lat : null,
        lng: userPosition ? userPosition.lng : null,
        os: 'web',
        user_id: uid
      })

      dispatch(enabledAlertsAndNewsComponent({ enabled: !alertsAndNews?.enabled, current: undefined }))

      if (pathname === '/news') {
        navigate('/')
        dispatch(setCurrentNavbarPosition(0))
      } else {
        navigate('/news')
      }
    } else {
      dispatch(setOpenCountryAlert(true))
    }
  }

  const handleLines = () => {
    if (cityConfig) {
      logEvent('lines', {
        lat: userPosition ? userPosition.lat : null,
        lng: userPosition ? userPosition.lng : null,
        user_id: uid,
        os: 'web',
        city_id: cityConfig?.city_id.toString()
      })

      navigate('/lines')
    } else {
      dispatch(setOpenCountryAlert(true))
    }
  }

  const handleShowRewards = () => {
    // log event
    const eventParams = {
      lat: userPosition?.lat ? userPosition.lat : null,
      lng: userPosition?.lng ? userPosition.lng : null,
      city_id: cityConfig?.city_id.toString(),
      user_id: auth.uid,
      user_birthday_timestamp: userData?.birthday?.long_value || null,
      user_gender: userData?.gender || null // String
    }

    logEvent('ticket_rewards', eventParams)
    if (!document.startViewTransition) {
      navigate(`${!userData ? '/login' : '/rewards/tickets'}`)
      return
    }

    // With a View Transition:
    document.startViewTransition(() => navigate(`${!userData ? '/login' : '/rewards/tickets'}`))
  }

  return (
    <Box
      sx={{
        zIndex: 403,
        position: 'absolute',
        bottom: 0,
        boxShadow: '0px -5px 20px 0px rgb(0 0 0 / 20%)',
        borderRadius: '10px 10px 0 0',
        width: '100%'
      }}
    >
      {openBottomSmartBanner && pathname === '/' && !filters.enabled && <SmartBottomBanner />}
      <BottomNavigation
        value={navbarPosition}
        onChange={(event, newValue) => {
          dispatch(setCurrentNavbarPosition(newValue))
        }}
        showLabels
        sx={{
          width: '100%',
          overflow: 'hidden',
          boxSizing: 'border-box',
          borderRadius: openBottomSmartBanner ? 0 : '10px 10px 0 0',
          display: isIOSApp ? 'none' : 'flex'

        }}
      >
        <BottomNavigationAction
          id='navbar-item-search'
          sx={{
            fontSize: '12px',
            whiteSpace: 'nowrap',
            color: theme.palette.icons.primary.main
          }}
          label={t('home.navbar.items.search')}
          icon={<MapRounded />}
          onClick={handleReturnHome}
        />
        <BottomNavigationAction
          id='navbar-item-lines'
          onClick={handleLines}
          sx={{
            fontSize: '12px',
            whiteSpace: 'nowrap',
            color: theme.palette.icons.primary.main
          }}
          label={t('home.navbar.items.lines')}
          icon={<CallSplit />}
        />

        {cityConfig?.config?.marketplace_enabled &&
          <BottomNavigationAction
            id='navbar-item-marketplace'
            onClick={() => dispatch(handleRecharge())}
            sx={{
              fontSize: '12px',
              whiteSpace: 'nowrap',
              color: theme.palette.icons.primary.main
            }}
            label={t('home.navbar.items.card_refill')}
            icon={<AttachMoneyRounded />}
          />}

        <BottomNavigationAction
          id='navbar-item-info'
          sx={{
            fontSize: '12px',
            whiteSpace: 'nowrap',
            color: theme.palette.icons.primary.main
          }}
          label={t('home.navbar.items.info_uppercase')}
          icon={<InfoRounded />}
          onClick={handleCityInfo}
        />

        <BottomNavigationAction
          id='navbar-item-alerts'
          sx={{
            fontSize: '12px',
            whiteSpace: 'nowrap',
            color: theme.palette.icons.primary.main
          }}
          label={t('home.navbar.items.alerts')}
          icon={<NotificationsRounded />}
          onClick={handleAlerts}
        />

        {REACT_APP_TICKETS_REWARDS_PROGRAM &&
          <BottomNavigationAction
            id='navbar-item-alerts'
            sx={{
              fontSize: '12px',
              whiteSpace: 'nowrap',
              color: theme.palette.icons.primary.main
            }}
            label={t('tickets_rewards_program.nav_bar_button')}
            icon={<ConfirmationNumberRoundedIcon />}
            onClick={handleShowRewards}
          />}
      </BottomNavigation>
    </Box>

  )
}

export default NavBar
