import { getAuth } from 'firebase/auth'
import { REACT_APP_DATABASE_HOST } from '../constants/config'

export const getTrips = async (params) => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'client-platform': 'web',
      'variant-id': process.env.REACT_APP_VARIANT_ID
    },
    body: JSON.stringify(params)
  }

  return fetch(`${REACT_APP_DATABASE_HOST}/tripPlanner/findTrips`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Error getting itineraries')
      }
    })
    .then((response) => {
      let itinerariesCar = []
      let itineraries = []

      if (response?.result?.itinerariesCar?.length > 0) {
        itinerariesCar = response?.result?.itinerariesCar?.map((itineraries, index) => ({
          ...itineraries,
          id: index
        }))
      }

      if (response?.result?.itineraries?.length > 0) {
        itineraries = response?.result?.itineraries?.map((itinerary) => {
          const trip = itinerary?.legs?.find((leg) => leg.mode !== 'WALK')
          return { ...itinerary, id: trip?.tripId }
        })
      }

      return { ...response?.result, itinerariesCar, itineraries }
    })
    .catch((err) => {
      console.error(err)
    })
}
