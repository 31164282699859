import { types } from '../types/types'
import { getCityByLatLng } from '../db/getCityByLatLng'
import PropTypes from 'prop-types'
import { getDynamicLinks } from '../db/gettDynamicLinks'
import { getCityConfig } from '../db/getCityConfig'
import { getLocalCityConfig } from '../db/getLocalCityConfig'
import { getPlaceByLatLng } from '../db/getPlaceByLatLng'
import {
  loadCurrentStop,
  setBikeStations,
  setBounds,
  setCenter,
  setCurrentBounds,
  setUserReports,
  setViewState,
  setZoom
} from './map'
import { setUserPosition, setUserSearchFilters } from './user'
import { setLocalCityConfig } from '../db/setLocalCityConfig'
import { db, logEvent, setUserProperties } from '../firebase/firebase-config'
import { getAllCountries } from '../db/getAllCountries'
import { getLocalCurrentCenter } from '../db/getLocalCurrentCenter'
import { getLocalCurrentZoom, setLocalCurrentZoom } from '../db/setLocalCurrentZoom'
import { setLocalCurrentCenter } from '../db/setLocalCurrentCenter'
import { getStopById } from '../db/getStopById'
import { getAllLines } from '../db/getAllLines'
import { getNews } from '../db/getNews'
import { getValidatedReports } from '../db/getValidatedReports'
import { getUserReportById } from '../db/getUserReportById'
import { getLocalAlertTypes } from '../db/getLocalAlertTypes'
import { getLocalAlertTypesUpdatedAt } from '../db/getLocalAlertTypesUpdatedAt'
import { loadAlertTypesUpdatedAt } from '../helpers/loadAlertTypesUpdatedAt'
import { loadAlertTypes } from '../helpers/loadAlertTypes'
import { setLocalAlertTypes } from '../db/setLocalAlertTypes'
import { setLocalAlertTypesUpdatedAt } from '../db/setLocalAlertTypesUpdatedAt'
import { getLocalFiltersConfig } from '../db/getLocalMapFilters'
import refillPointsIcon from '../img/bocaderecargaicon.svg'
import userReportsIcon from '../img/userreporticon.svg'
import { setLocalFiltersConfig } from '../db/setLocalMapFilters'
import { getLocalResultFilters } from '../db/getLocalResultFilters'
import { setLocalResultFilters } from '../db/setLocalResultFilters'
import { getTrips } from '../db/getTrips'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { getRoutes } from '../db/getRoutes'
import polyUtil from 'polyline-encoded'
import { getLocalShowMenuNotification } from '../db/localStorage'
import { getCustomToken } from '../db/auth/getCustomToken'
import i18next from '../i18n'
import { getBestContrastColor, getClosetsBikeStation } from '../utils'
import { getBikeNetworksByCity } from '../db/getBikeNetwotksByCity'
import { getGraphhopperRoute } from '../db/getGraphhopperRoute'
import { getVehiclePositionsByRoute } from '../db/getVehiclePositionsByRoute'
import { getArrivalsRealTime } from '../db/getArrivalsRealTime'
import maplibregl from 'maplibre-gl'
import { getAdsByCity } from '../db/ads'
import _ from 'lodash'
import { REACT_APP_HARDCODED_CITY_ID, REACT_APP_APP_NAME, REACT_APP_VARIANT_ID, REACT_APP_SORT_ROUTE_TYPE_ID, REACT_APP_SHOW_SUBWAY_AND_TRAIN_NETWORKS, REACT_APP_CHANGE_ROUTE_COLOR_ON_TRANSFERS, REACT_APP_SHOW_MAP_USER_REPORTS } from '../constants/config'
import MapRoundedIcon from '@mui/icons-material/MapRounded'
import { theme } from '../theme/theme'

export const setSearch = (term, timestamp) => ({
  type: types.searchValue,
  payload: {
    term, createdAt: timestamp
  }
})

export const setResults = (results) => ({
  type: types.autocompleteResults, payload: results
})

export const setFromPlacesSelected = (from) => ({
  type: types.placesSelectedFrom, payload: from
})

export const setToPlacesSelected = (to) => ({
  type: types.placesSelectedTo, payload: to
})

export const setAutocompleteOrientation = (value) => ({
  type: types.autocompleteOrientation, payload: value
})

export const setSelectDateTimeLabel = (label) => ({
  type: types.selectDateTimeLabel, payload: label
})

export const setDataTimeValue = (value) => ({
  type: types.dateTimePickerValue, payload: value
})

export const setTripSelected = (trip) => ({
  type: types.tripSelected, payload: trip
})

export const setTripDetails = (tripDetails) => ({
  type: types.tripDetails, payload: tripDetails
})

export const setTripSelectedVehicles = (tripSelectedVehicles) => ({
  type: types.tripSelectedVehicles, payload: tripSelectedVehicles
})

export const setLineSelected = (line) => ({
  type: types.lineSelected, payload: line
})

export const setStopSelected = (stop) => ({
  type: types.stopSelected, payload: stop
})

export const setStopSecurity = (info) => ({
  type: types.security, payload: info
})

export const setTimestamp = (time) => ({
  type: types.timestamp, payload: time
})

/*
export const setOpenCovidCenter = (boolean) => ({
  type: types.openCovidCenter, payload: boolean
})
*/

export const setLines = (lines) => ({
  type: types.lines, payload: lines
})

export const setOpenStop = (boolean) => ({
  type: types.stop, payload: boolean
})

export const setShowUsefulInformation = (boolean) => ({
  type: types.showUsefulInformation, payload: boolean
})

export const setButtonNavbarSelected = (string) => ({
  type: types.buttonNavbarSelected, payload: string
})

export const setCurrentCityConfig = (obj) => ({
  type: types.cityConfig, payload: obj
})

export const setOpenIdentifiedCityModal = (boolean) => ({
  type: types.openCityModal, payload: boolean
})

export const setOpenTripPreview = (boolean) => ({
  type: types.tripPreview, payload: boolean
})

export const setOpenTripResults = (boolean) => ({
  type: types.tripResults, payload: boolean
})

export const setShowFilters = (boolean) => ({
  type: types.filters, payload: boolean
})

export const setIdentifiedCity = (city) => ({
  type: types.identifiedCity, payload: city
})

export const setAlerts = (alerts) => ({
  type: types.alerts, payload: alerts
})

export const handleLocationClick = (map) => {
  return async (dispatch, getState) => {
    const { user: { userPosition }, map: { bounds } } = getState()

    if (bounds?.contains(new maplibregl.LngLat(userPosition?.lng, userPosition?.lat))) {
      map?.flyTo({ center: [userPosition?.lng, userPosition?.lat], zoom: 17 })
    } else if (!REACT_APP_HARDCODED_CITY_ID) {
      try {
        const cityConfig = await getCityByLatLng(userPosition)
        if (cityConfig) {
          dispatch(setOpenIdentifiedCityModal(true))
          dispatch(setIdentifiedCity(cityConfig))
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
}

export const setCountries = (list) => ({
  type: types.countries, payload: list
})

export const setCities = (list) => ({
  type: types.cities, payload: list
})

export const setShowLines = (boolean) => ({
  type: types.linesComponent, payload: boolean
})

export const enableTransportFiltersComponent = (boolean) => ({
  type: types.transportFiltersComponent, payload: boolean
})

export const enabledAlertsAndNewsComponent = (boolean) => ({
  type: types.alertsAndNewsComponent, payload: boolean
})

export const setShowNavbar = (boolean) => ({
  type: types.navbar, payload: boolean
})

export const setOpenInvitationMessage = (obj) => ({
  type: types.invitationMessage, payload: obj
})

export const setShowSearcher = (boolean) => ({
  type: types.search, payload: boolean
})

export const setLineRoutes = (routes) => ({
  type: types.routes, payload: routes
})

/*
export const setLineData = (obj) => ({
    type: types.data,
    payload: obj
}) */

export const setOpenMapFilters = (boolean) => ({
  type: types.mapFilters, payload: boolean
})

export const setTripResponse = (obj) => {
  if (REACT_APP_SORT_ROUTE_TYPE_ID && obj) {
    obj.itineraries.sort((a, b) => {
      const aHasSortRouteType = a.legs.some(leg => leg.routeType === REACT_APP_SORT_ROUTE_TYPE_ID)
      const bHasSortRouteType = b.legs.some(leg => leg.routeType === REACT_APP_SORT_ROUTE_TYPE_ID)

      if (aHasSortRouteType && bHasSortRouteType) {
        return 0
      } else if (aHasSortRouteType) {
        return -1
      } else if (bHasSortRouteType) {
        return 1
      } else {
        return 0
      }
    })
  }
  return { type: types.tripResponse, payload: obj }
}

export const setNoLoginDialog = (boolean) => ({
  type: types.noLoginDialog, payload: boolean
})

export const setStopArrivals = (arrivals) => ({
  type: types.arrivals, payload: arrivals
})

export const setOpenAlertInformation = (boolean) => ({
  type: types.openAlertInformation, payload: boolean
})

export const setUserReportDrawerRef = (value) => ({
  type: types.userReportDrawerRef, payload: value
})

export const handleClickWebToAppButton = (boolean, props, logEvent) => {
  return (dispatch) => {
    dispatch(setOpenInvitationMessage({ enabled: boolean, props }))
    logEvent('web_to_app_conversion', props)
  }
}

handleClickWebToAppButton.propTypes = {
  boolean: PropTypes.bool.isRequired, props: PropTypes.object.isRequired, logEvent: PropTypes.func.isRequired
}

export const setStopPictures = (obj) => ({ // show or hide stop pictures
  type: types.openMoreStopPictures, payload: obj
})

export const setOpenArrivalInformation = (boolean) => ({ // show or hide stop pictures
  type: types.openStopArrivalTimeInformation, payload: boolean
})

export const setOpenStopOptions = (boolean) => ({ // show or hide stop options
  type: types.openStopOptions, payload: boolean
})

export const setOpenOccupancyLevel = (obj) => ({ // show or hide stop occupancy level dialog
  type: types.openOccupancyLevel, payload: obj
})

export const setCurrentStopPictures = (pictures) => ({
  type: types.stopPictures, payload: pictures
})

export const setOpenLoginPopup = (boolean) => ({
  type: types.openLoginPopup, payload: boolean
})

export const setOpenShareModal = (obj) => ({ // set show or hide share modal and params
  type: types.openShareModal, payload: obj
})

export const handleClickDesktopShare = (params, setLoading) => {
  return async (dispatch) => {
    try {
      const response = await getDynamicLinks(params)
      setLoading(false)
      return dispatch(setOpenShareModal({
        enabled: true, params: response
      }))
    } catch (e) {
      dispatch(handleOpenAlert({
        title: i18next.t('invitation_message.link_error_message'),
        severity: 'error'
      }))
      console.error(e)
    }
  }
}

export const setOpenShareDrawer = (obj) => ({ // set show or hide share modal and params
  type: types.openShareDrawer, payload: obj
})

export const handleClickMobileShare = (params, setLoading) => {
  return async (dispatch) => {
    const response = await getDynamicLinks(params)
    setLoading(false)
    if (!response?.shortLink) return
    if (navigator.canShare) {
      const shareData = {
        title: params?.title, text: params?.description, url: response?.shortLink
      }

      try {
        await navigator.share(shareData)
      } catch (err) {
        console.error(err)
      }
    } else {
      dispatch(setOpenShareDrawer({
        enabled: true, params: response, linkData: params
      }))
    }
  }
}

export const setOpenSendToPhoneModal = (obj) => ({ // set show or hide share modal and params
  type: types.openSendToPhoneModal, payload: obj
})

export const handleClickSendToPhone = (params, setLoading, notificationBody) => {
  return async (dispatch) => {
    try {
      const response = await getDynamicLinks(params)
      setLoading(false)
      if (!response.shortLink) return
      dispatch(setOpenSendToPhoneModal({
        enabled: true, params: response, notificationBody, link: params?.link
      }))
    } catch (e) {
      dispatch(handleOpenAlert({
        title: i18next.t('invitation_message.link_error_message'),
        severity: 'error'
      }))
      console.error('Error getting dynamic links', e)
    }
  }
}

export const setCurrentFilters = (filters) => ({
  type: types.currentFilters, payload: filters
})

export const setLoading = (boolean) => ({
  type: types.loading, payload: boolean
})

export const setOpenCountries = (boolean) => ({
  type: types.openCountries, payload: boolean
})

export const setCityIdentifiedDismissed = (boolean) => ({
  type: types.cityIdentifiedDismissed, payload: boolean
})

export const setCountrySelected = (value) => ({
  type: types.countrySelected, payload: value
})

export const setOpenCities = (boolean) => ({
  type: types.openCities, payload: boolean
})

export const setOpenMenu = (boolean) => ({
  type: types.menu, payload: boolean
})

export const setOpenCountryAlert = (boolean) => ({
  type: types.openCountryAlert, payload: boolean
})

export const setCurrentNavbarPosition = (position) => ({
  type: types.navbarPosition,
  payload: position
})

/*
export const setHelmetData = (data) => ({
  type: types.helmet,
  payload: data
})
*/

export const setOpenSearch = (boolean) => ({
  type: types.search, payload: boolean
})

export const setAppConfiguration = () => {
  return async (dispatch) => {
    const showMenuNotification = await getLocalShowMenuNotification()
    dispatch(setShowMenuNotification(showMenuNotification))

    if (localStorage.getItem('savedPlaces')) {
      localStorage.removeItem('savedPlaces')
    }

    const localCityConfig = getLocalCityConfig()

    if (localCityConfig?.ciudad_id) {
      try {
        const cityConfig = await getCityConfig(localCityConfig?.ciudad_id)
        setLocalCityConfig(cityConfig)
      } catch (e) {
        console.error(e)
      }
    }

    navigator.geolocation.getCurrentPosition(async (position) => {
      const userPosition = {
        lat: position?.coords?.latitude,
        lng: position?.coords?.longitude
      }

      if (localCityConfig) {
        const sw = new maplibregl.LngLat(localCityConfig.map.map_southwest_lng, localCityConfig.map.map_southwest_lat)
        const ne = new maplibregl.LngLat(localCityConfig.map.map_northeast_lng, localCityConfig.map.map_northeast_lat)
        const bounds = new maplibregl.LngLatBounds(sw, ne)

        if (bounds && !bounds?.contains(new maplibregl.LngLat(userPosition?.lng, userPosition?.lat)) && !REACT_APP_HARDCODED_CITY_ID) {
          try {
            const cityConfig = await getCityByLatLng(userPosition)
            if (cityConfig) {
              dispatch(setOpenIdentifiedCityModal(true))
              dispatch(setIdentifiedCity(cityConfig))
            }
          } catch (e) {
            console.error(e)
          }
        }
      }

      dispatch(setUserPosition(userPosition))
    }, (e) => {
      console.error(e)
    }, {
      enableHighAccuracy: false, timeout: 5000, maximumAge: 300000
    })
  }
}

export const verifyAlerts = () => {
  return async (dispatch, getState) => {
    const { ui: { cityConfig, alerts: alertsAndNews } } = getState()
    const pathname = window.location.pathname

    // Get data from localStorage
    const alertTypes = await getLocalAlertTypes()
    const alertTypesUpdatedAt = getLocalAlertTypesUpdatedAt()

    // Get alert types document from firebase
    const updatedAt = await loadAlertTypesUpdatedAt()

    if (alertTypesUpdatedAt && !alertTypes || alertTypesUpdatedAt !== updatedAt) {
      // Get alert types collection from firebase
      const alertTypes = await loadAlertTypes()

      // Set data in localStorage
      setLocalAlertTypes(alertTypes)
      setLocalAlertTypesUpdatedAt(updatedAt)
    }

    const news = await getNews(cityConfig?.city_id, db)

    let alerts = []

    if (REACT_APP_SHOW_MAP_USER_REPORTS) {
      alerts = await getValidatedReports(cityConfig?.city_id) || []
    }

    const currentNews = news?.map((item) => {
      return {
        id: item.id,
        cityId: item.city_id,
        typeName: item.alert_type_name,
        address: item.address,
        userNickname: REACT_APP_APP_NAME,
        pictureUrl: item?.picture_uri !== undefined ? item?.picture_uri : cityConfig?.map.landscape_picture,
        date: item.date_from.seconds * 1000,
        icon: item.icon,
        title: item.title ? item.title : '',
        content: item.content,
        affectedLines: item.affected_lines
          ? item.affected_lines.map((line) => {
            return { name: line.route_name, color: line.color }
          })
          : null,
        isAlert: item.isAlert,
        lat: item.location.latitude,
        lng: item.location.longitude,
        validate: true,
        link: item.link
      }
    })

    const currentAlerts = alerts?.map(alert => ({
      id: alert.id,
      cityId: alert.city_id,
      address: alert.address,
      userNickname: alert.user_nickname,
      pictureUrl: alert.report_picture_url || cityConfig.map.landscape_picture,
      date: alert.created_at,
      icon: alert.icon_url,
      title: alert.report_text,
      affectedLines: alert?.affected_routes_json_array?.map((line) => ({
        name: line?.route_short_name || line?.route_long_name, color: line.color
      })),
      isAlert: true,
      lat: alert.lat,
      lng: alert.lng,
      validate: alert.status_id === 1,
      link: '',
      content: '',
      reportType: alert.report_type
    }))

    dispatch(setUserReports({
      enabled: true, reports: currentAlerts
    }))

    dispatch(setAlerts([...currentAlerts, ...currentNews].filter(Boolean)))

    if (pathname?.includes('news')) {
      if (pathname?.split('/')[2] && alerts) {
        const current = currentNews?.find((alert) => alert?.id === pathname?.split('/')[2])

        if (current) {
          dispatch(enabledAlertsAndNewsComponent({
            enabled: true, current: { ...current, isAlert: false }
          }))

          dispatch(setOpenAlertInformation(true))
        }
      } else if (pathname === '/news' && !alertsAndNews?.enabled) {
        dispatch(enabledAlertsAndNewsComponent({ enabled: true }))
      }
    } else if (pathname.includes('user_reports')) {
      if (!alertsAndNews?.current) {
        const { result: alert } = await getUserReportById(pathname?.split('/')[3], pathname?.split('/')[2])

        const currentAlert = {
          id: alert.id,
          cityId: alert.city_id,
          address: alert.address,
          userNickname: alert.user_nickname,
          pictureUrl: alert.report_picture_url || cityConfig.map.landscape_picture,
          date: alert.created_at,
          icon: alert.icon_url,
          title: alert.report_text,
          affectedLines: alert?.affected_routes_json_array?.map((line) => {
            return { name: line?.route_short_name || line?.route_long_name, color: line.color }
          }),
          isAlert: true,
          lat: alert.lat,
          lng: alert.lng,
          validate: alert.status_id === 1,
          link: '',
          content: '',
          reportType: alert.report_type
        }

        dispatch(enabledAlertsAndNewsComponent({
          enabled: false, current: currentAlert
        }))

        dispatch(setOpenAlertInformation(true))
      }
    }
  }
}

export const verifyTripShare = () => {
  return async (dispatch) => {
    const query = new URLSearchParams(window.location.search)
    const pathname = window.location.pathname

    const params = {
      lat_from: query.get('latd'),
      lng_from: query.get('lngd'),
      lat_to: query.get('lath'),
      lng_to: query.get('lngh')
    }

    if (query?.get('ciudad_id') && pathname?.includes('trip_share')) {
      const cityConfig = await getCityConfig(parseInt(query.get('ciudad_id')))
      dispatch(setCurrentCityConfig(cityConfig)) // Save cityConfig in redux
      setUserProperties({
        current_city: cityConfig?.name.toString(),
        current_city_id: cityConfig?.city_id.toString(),
        country: cityConfig?.country.name.toString(),
        country_id: cityConfig?.country.country_id.toString()
      })
      if (pathname?.includes('trip_share') && params?.lat_from && params?.lng_from) {
        const from = await getPlaceByLatLng({
          lat: params.lat_from, lng: params.lng_from
        }, cityConfig)

        dispatch(setFromPlacesSelected(from))

        const to = await getPlaceByLatLng({
          lat: query.get('lath'), lng: query.get('lngh')
        }, cityConfig)

        dispatch(setToPlacesSelected(to))
      }
    }
  }
}

export const setCityConfig = (userPosition, cityId) => {
  return async (dispatch, getState) => {
    const { user: { auth, userData } } = getState()

    const query = new URLSearchParams(window.location.search)
    const localCityConfig = getLocalCityConfig()

    if (localCityConfig?.city_id && !REACT_APP_HARDCODED_CITY_ID) {
      getCityConfig(parseInt(localCityConfig.city_id.toString()))
        .then((cityConfig) => {
          setLocalCityConfig(cityConfig)
        })
        .catch(e => console.error(e))
    }

    const localCenter = getLocalCurrentCenter()

    let [latitude, longitude] = localCenter || []
    let zoom = getLocalCurrentZoom()

    let cityConfig

    if (REACT_APP_HARDCODED_CITY_ID) { // Use case: white label hardcoded city id
      if (localCityConfig?.city_id === REACT_APP_HARDCODED_CITY_ID) {
        cityConfig = localCityConfig
      } else {
        try {
          const res = await getCityConfig(Number(REACT_APP_HARDCODED_CITY_ID))
          cityConfig = res
          setLocalCityConfig(res)
          setLocalCurrentCenter([Number(res?.map?.map_center_lat), Number(res?.map?.map_center_lng)])
          setLocalCurrentZoom(res?.map?.map_zoom_level)
        } catch (e) {
          console.error(e)
        }
      }
    } else if (cityId) {
      if (localCityConfig && cityId === localCityConfig?.city_id.toString()) {
        cityConfig = localCityConfig
      } else {
        try {
          cityConfig = await getCityConfig(parseInt(cityId))
          setLocalCityConfig(cityConfig)
          setLocalCurrentCenter([Number(cityConfig?.map?.map_center_lat), Number(cityConfig?.map?.map_center_lng)])
          setLocalCurrentZoom(cityConfig?.map?.map_zoom_level)
        } catch (e) {
          console.error(e)
        }
      }
    } else if (localCityConfig) { // Use case: user has a cityConfig in localStorage
      if (query.get('ciudad_id') === localCityConfig?.city_id.toString()) {
        cityConfig = await getCityConfig(parseInt(query.get('ciudad_id')))
      } else {
        cityConfig = localCityConfig
      }
    } else if (userPosition) {
      try {
        const userCityConfig = await getCityByLatLng(userPosition)

        if (userCityConfig) {
          cityConfig = userCityConfig

          setLocalCityConfig(cityConfig)
          setLocalCurrentCenter([Number(cityConfig?.map?.map_center_lat), Number(cityConfig?.map?.map_center_lng)])
          setLocalCurrentZoom(cityConfig?.map?.map_zoom_level)
        } else {
          logEvent('city_not_available', {
            last_city_id: localCityConfig?.city_id?.toString(), // String
            lat: userPosition?.lat, // Double
            lng: userPosition?.lng, // Double
            user_id: auth?.uid, // String
            user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
            user_gender: userData?.gender || null // String
          })

          const [countries, defaultConfig] = await Promise.all([
            getAllCountries(),
            getCityConfig(1)
          ])

          dispatch(setViewState({
            latitude: defaultConfig.map.map_center_lat,
            longitude: defaultConfig.map.map_center_lng,
            zoom: defaultConfig.map.map_zoom_level
          }))

          dispatch(setCountries(countries))
          dispatch(setOpenCountries(true))
        }
      } catch (e) {
        console.error(e)
      }
    } else {
      try {
        const [countries, defaultConfig] = await Promise.all([
          getAllCountries(),
          getCityConfig(1)
        ])
        dispatch(setViewState({
          latitude: defaultConfig.map.map_center_lat,
          longitude: defaultConfig.map.map_center_lng,
          zoom: defaultConfig.map.map_zoom_level
        }))

        dispatch(setCountries(countries))
        dispatch(setOpenCountries(true))
      } catch (e) {
        console.error(e)
      }
    }

    if (!cityConfig) return

    const sw = new maplibregl.LngLat(cityConfig.map.map_southwest_lng, cityConfig.map.map_southwest_lat)
    const ne = new maplibregl.LngLat(cityConfig.map.map_northeast_lng, cityConfig.map.map_northeast_lat)
    const bounds = new maplibregl.LngLatBounds(sw, ne)

    dispatch(setBounds(bounds))

    if (!latitude || !longitude) {
      latitude = cityConfig.map.map_center_lat
      longitude = cityConfig.map.map_center_lng
    }

    if (!zoom) {
      zoom = cityConfig.map.map_zoom_level
    }

    if (!bounds.contains({ lat: latitude, lng: longitude })) {
      latitude = cityConfig.map.map_center_lat
      longitude = cityConfig.map.map_center_lng
      zoom = cityConfig.map.map_zoom_level

      setLocalCityConfig(cityConfig)
      setLocalCurrentCenter([Number(cityConfig?.map?.map_center_lat), Number(cityConfig?.map?.map_center_lng)])
      setLocalCurrentZoom(cityConfig?.map?.map_zoom_level)
    }

    dispatch(setViewState({ latitude, longitude, zoom }))

    getAdsByCity(cityConfig?.city_id)
      .then((response) => {
        if (response?.rotationTime?.some(item => item.ad_type_id === 20)) { // it means that there are some Search results advertising to show
          dispatch(setCityAds(response))
        } else {
          dispatch(setAdsList([]))
        }
      })
      .catch(e => console.error(e))

    dispatch(setCurrentCityConfig(cityConfig))
    setUserProperties({
      current_city: cityConfig?.name.toString(),
      current_city_id: cityConfig?.city_id.toString(),
      country: cityConfig?.country.name.toString(),
      country_id: cityConfig?.country.country_id.toString()
    })
    dispatch(setMapConfiguration())
    dispatch(setTripFilters(cityConfig))
    dispatch(verifyStop())
    dispatch(verifyAlerts())
    dispatch(verifyTripShare())
    dispatch(verifyCityInfo())
    dispatch(setFiltersConfig())
  }
}

export const setMapConfiguration = () => {
  return (dispatch, getState) => {
    const { ui: { cityConfig }, user: { userPosition } } = getState()

    const query = new URLSearchParams(window.location.search)
    const pathname = window.location.pathname

    const params = {
      lat_from: query.get('latd'), lng_from: query.get('lngd')
    }

    let currentCenter = getLocalCurrentCenter()
    let currentZoom = getLocalCurrentZoom()

    if (!currentCenter) {
      currentCenter = [cityConfig?.map?.map_center_lat, cityConfig?.map?.map_center_lng]
    }

    if (!currentZoom) {
      currentZoom = cityConfig?.map?.map_zoom_level
    }

    const sw = new maplibregl.LngLat(cityConfig.map.map_southwest_lng, cityConfig.map.map_southwest_lat)
    const ne = new maplibregl.LngLat(cityConfig.map.map_northeast_lng, cityConfig.map.map_northeast_lat)
    const newBounds = new maplibregl.LngLatBounds(sw, ne)

    if (!(pathname?.includes('trip_share') && params?.lat_from && params?.lng_from)) {
      if (userPosition && newBounds?.contains(new maplibregl.LngLat(userPosition?.lng, userPosition?.lat))) {
        dispatch(setFromPlacesSelected({ name: i18next.t('my_location'), latLng: userPosition }))
      } else {
        dispatch(setFromPlacesSelected({
          name: i18next.t('hint_location_A'),
          latLng: {
            lat: cityConfig?.map?.origin_marker_lat, lng: cityConfig?.map?.origin_marker_lng
          }
        }))
      }

      dispatch(setToPlacesSelected({
        name: i18next.t('hint_location_B'),
        latLng: { lat: cityConfig.map.destination_marker_lat, lng: cityConfig.map.destination_marker_lng }
      }))
    }

    if (currentCenter?.length === 2 && newBounds?.contains(new maplibregl.LngLat(currentCenter[1], currentCenter[0]))) {
      dispatch(setZoom(currentZoom))
      dispatch(setCenter(currentCenter))

      dispatch(setCurrentBounds(newBounds))
      dispatch(setBounds(newBounds))
    } else {
      const center = [Number(cityConfig?.map?.map_center_lat), Number(cityConfig?.map?.map_center_lng)]
      const zoom = cityConfig?.map?.map_zoom_level

      dispatch(setCenter(center))
      dispatch(setZoom(zoom))

      dispatch(setCurrentBounds(newBounds))
      dispatch(setBounds(newBounds))

      setLocalCurrentZoom(zoom)
      setLocalCurrentCenter(center)
    }
  }
}

export const verifyStop = () => {
  return async (dispatch, getState) => {
    const { ui: { cityConfig, stopSelected } } = getState()
    const pathname = window.location.pathname
    const query = new URLSearchParams(window.location.search)

    const params = {
      city_id: Number(pathname?.split('/')[2]),
      stop_id: decodeURI(pathname?.split('/')[3])
    }

    if (pathname?.includes('stops')) {
      // Get data from localStorage
      const alertTypes = await getLocalAlertTypes()

      if ((params?.city_id && params?.stop_id && !stopSelected) || (stopSelected && stopSelected?.stop_id !== params?.stop_id)) {
        try {
          const stop = await getStopById(params)

          if (stop) {
            dispatch(setStopSelected(stop))
            dispatch(loadCurrentStop(stop, params))

            const reportId = query.get('user_reports')

            if (reportId) {
              dispatch(setOpenAlertInformation(true))

              getUserReportById(reportId, cityConfig?.city_id)
                .then((alert) => {
                  const currentAlert = {
                    id: alert.id,
                    cityId: alert.city_id,
                    typeName: window.navigator.language.toLowerCase().includes('es') ? alertTypes?.filter((type) => type.id === alert.report_type)[0]?.name_es : alertTypes?.filter((type) => type.id === alert.report_type)[0]?.name,
                    address: alert.address,
                    userNickname: alert.user_nickname,
                    pictureUrl: alert.report_picture_url || cityConfig.map.landscape_picture,
                    date: alert.created_at,
                    icon: alert.icon_url,
                    title: alert.report_text,
                    affectedLines: alert.affected_routes_json_array.map((line) => {
                      return {
                        name: line?.route_short_name || line?.route_long_name,
                        color: line.color
                      }
                    }),
                    isAlert: true,
                    lat: alert.lat,
                    lng: alert.lng,
                    validate: alert.status_id === 1,
                    link: '',
                    content: '',
                    reportType: alert.report_type
                  }

                  dispatch(enabledAlertsAndNewsComponent({
                    enabled: false, current: currentAlert
                  }))
                })
                .catch(e => console.error(e))
            }
          } else {
            console.error('Error getting current stop by id')
          }
        } catch (e) {
          console.error('Error getting current stop by id', e)
        }
      }
    }
  }
}

export const verifyLines = (mobile) => {
  return async (dispatch, getState) => {
    let { ui: { cityConfig, lines }, user: { lines: userLines } } = getState()

    const localCityConfig = getLocalCityConfig()
    const linesFavs = userLines?.linesFav || []

    if (!cityConfig && localCityConfig) {
      cityConfig = localCityConfig
    }

    if (!lines || lines?.length === 0) {
      try {
        const lines = await getAllLines(cityConfig?.city_id)

        let currentLines = lines?.map(line => ({
          ...line,
          fav: linesFavs?.some((current) => line.route_id === current.line_id),
          transport_type_id: line.transport_type.transport_type_id
        }))

        currentLines = _.orderBy(currentLines, ['route_short_name'], ['asc'])
        currentLines = _.orderBy(currentLines, ['route_long_name'], ['asc'])
        currentLines = _.orderBy(currentLines, ['fav'], ['desc'])

        dispatch(setLines(currentLines))
        if (!mobile) {
          dispatch(setLinesByAgency(0, currentLines))
        }
      } catch (e) {
        console.error(e)
      }
    } else {
      lines = _.orderBy(lines, ['route_short_name'], ['asc'])
      lines = _.orderBy(lines, ['route_long_name'], ['asc'])
      lines = _.orderBy(lines, ['fav'], ['desc'])
      dispatch(setCurrentLines(lines))
    }
  }
}

/** verifyLine()
 * Verifies if the line selected doesn't exist.
 * @param {object} cityConfig
 * @param {object} params
 * @param {function} setTripSelected
 * @returns {(function(*, *): Promise<void>)|*}
 */

export const verifyLine = (cityConfig, params, setTripSelected) => {
  return async (dispatch, getState) => {
    const { user: { lines: userLines } } = getState()

    const linesFav = userLines?.linesFav || []

    try {
      const reqBody = {
        city_id: parseInt(params?.cityId), route_id: params?.routeId
      }

      const response = await getRoutes(reqBody)

      const lineTrips = response.trips?.map(trip => ({ ...trip, points: polyUtil.decode(trip.shape) }))

      dispatch(setLineSelected({
        ...response, trips: lineTrips, fav: linesFav?.some(line => line?.line_id === response?.route_id)
      }))

      dispatch(setLineRoutes(lineTrips))

      const tripSelected = lineTrips.find(trip => trip.shape_id === params.shapeId)
      setTripSelected(tripSelected || lineTrips[0])
    } catch (e) {
      console.error('Error getting current line', e)
    }
  }
}

verifyLine.propTypes = {
  cityConfig: PropTypes.object.isRequired, params: PropTypes.object.isRequired
}

export const verifyCityInfo = () => {
  return async (dispatch, getState) => {
    const { ui: { cityConfig, components } } = getState()

    const pathname = window.location.pathname

    if (pathname?.includes('city_info') && !components?.city_info) {
      const ref = collection(db, `cities/${cityConfig?.city_id}/info`)
      try {
        const response = await
        getDocs(query(ref, where('variant_id', '==', REACT_APP_VARIANT_ID), where('enabled', '==', true)))

        const cityList = response?.docs?.map((item) => {
          const obj = item?.data()
          return { ...obj, id: item.id }
        })

        if (pathname?.split('/')[3]) {
          const citySelected = cityList.find(city => city.id === pathname?.split('/')[3])
          dispatch(setCityInfoSelected(citySelected))
        }

        dispatch(setCityInfoList(cityList))
      } catch (e) {
        console.error(e)
      }
    }
  }
}

export const setCityInfoList = (list) => ({
  type: types.cityInfo, payload: list
})

export const setCityInfoSelected = (object) => ({
  type: types.cityInfoSelected, payload: object
})

export const handleCityInfoClick = (item, navigate) => {
  return (dispatch, getState) => {
    const { user: { userPosition, userData }, ui: { cityConfig } } = getState()

    const { content, id, color, title } = item

    const eventParams = {
      lat: userPosition?.lat || null,
      lng: userPosition?.lng || null,
      city_id: cityConfig?.city_id?.toString(),
      city_info_id: id?.toString(), // String
      user_id: sessionStorage.getItem('uid'),
      os: 'web',
      user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
      user_gender: userData?.gender || null, // String
      title, // String
      color // String
    }

    logEvent('city_info_details', eventParams)

    if (content?.startsWith('<p>https://') || content?.startsWith('https://')) {
      const url = content?.toLowerCase()?.replace('<p>', '').replace('</p>', '')
      window.open(url, '_blank')
      dispatch(setCityInfoSelected(undefined))
    } else {
      dispatch(setCityInfoSelected(item))
      navigate(`/city_info/${cityConfig.city_id}/${id}`)
    }
  }
}

export const setFiltersConfig = () => {
  return async (dispatch, getState) => {
    const { ui: { cityConfig } } = getState()

    const localFilters = getLocalFiltersConfig()

    const currentLocalFilters = localFilters?.filter(current => current?.city_id === cityConfig?.city_id)[0]

    const options = [{
      checked: false,
      color: null,
      flat_icon: null,
      icon: refillPointsIcon,
      name: i18next.t('home.filters.items.refill_points'),
      third_party_search: true,
      transport_type_id: 0
    }]

    if (REACT_APP_SHOW_SUBWAY_AND_TRAIN_NETWORKS) {
      options.push({
        checked: true,
        color: null,
        flat_icon: null,
        icon: null,
        mui_icon: <MapRoundedIcon
          sx={{
            color: theme.palette.primary.main,
            width: '25px',
            height: '25px',
            margin: '0 16px 0 0'
          }}
                  />,
        name: i18next.t('home.filters.items.network_map'),
        third_party_search: true,
        transport_type_id: 'network_map'
      })
    }

    if (REACT_APP_SHOW_MAP_USER_REPORTS) {
      options.push({
        checked: !REACT_APP_SHOW_SUBWAY_AND_TRAIN_NETWORKS,
        color: null,
        flat_icon: null,
        icon: userReportsIcon,
        name: i18next.t('home.filters.items.user_reports'),
        third_party_search: true,
        transport_type_id: -1
      })
    }

    if (currentLocalFilters) {
      const bikeStationsFilter = currentLocalFilters?.filters?.filter((filter) => filter?.id === 5)[0]

      const bikeTransportType = cityConfig?.transport_types?.filter((transport) => {
        if (transport.transport_type_id === 5) {
          return { ...transport, checked: !REACT_APP_SHOW_SUBWAY_AND_TRAIN_NETWORKS && bikeStationsFilter?.show }
        }
      })[0]

      const showBikeNetwork = cityConfig?.transport_types?.filter((type) => {
        return type.transport_type_id === 5
      })[0]

      const transportFilters = cityConfig?.transport_types?.filter((transport) => {
        const current = currentLocalFilters?.filters?.filter((filter) => filter?.id === transport.transport_type_id)[0]
        transport.checked = !REACT_APP_SHOW_SUBWAY_AND_TRAIN_NETWORKS && current?.show
        return transport?.transport_type_id === current?.id && transport?.transport_type_id !== 5
      })

      if (cityConfig?.config?.bike_networks && showBikeNetwork) {
        const currentFilters = [...transportFilters, bikeTransportType, ...options].map((filter) => {
          return {
            ...filter,
            alone: filter.transport_type_id === 0 || filter.transport_type_id === 'network_map'
          }
        })

        dispatch(setCurrentFilters(currentFilters))
      } else {
        const currentFilters = [...transportFilters, ...options].map((filter) => {
          return {
            ...filter,
            alone: filter.transport_type_id === 0 || filter.transport_type_id === 'network_map'
          }
        })

        dispatch(setCurrentFilters(currentFilters))
      }
    } else {
      const bikeTransportType = cityConfig?.transport_types?.filter((transport) => {
        if (transport.transport_type_id === 5) {
          return { ...transport, checked: !REACT_APP_SHOW_SUBWAY_AND_TRAIN_NETWORKS && true }
        }
      })[0]

      const showBikeNetwork = cityConfig?.transport_types?.filter((type) => {
        return type.transport_type_id === 5
      })[0]

      const transportFilters = cityConfig?.transport_types?.filter((type) => {
        type.checked = !REACT_APP_SHOW_SUBWAY_AND_TRAIN_NETWORKS && true
        return !type?.third_party_search
      })

      const filters = transportFilters?.map((type) => {
        return {
          id: type.transport_type_id, show: !REACT_APP_SHOW_SUBWAY_AND_TRAIN_NETWORKS && true
        }
      })

      if (cityConfig?.config?.bike_networks && showBikeNetwork) {
        const others = [
          { id: 0, show: !REACT_APP_SHOW_SUBWAY_AND_TRAIN_NETWORKS && false }, // Refill points
          { id: -1, show: !REACT_APP_SHOW_SUBWAY_AND_TRAIN_NETWORKS && true }, // Users reports
          { id: 5, show: !REACT_APP_SHOW_SUBWAY_AND_TRAIN_NETWORKS && true } // Bike stations,
        ]

        if (REACT_APP_SHOW_SUBWAY_AND_TRAIN_NETWORKS) {
          others.push({ id: 'network_map', show: true })
        }

        setLocalFiltersConfig(localFilters?.length >= 1
          ? [...localFilters, {
              filters: [...filters, ...others], city_id: cityConfig?.city_id
            }]
          : [{ filters: [...filters, ...others], city_id: cityConfig?.city_id }])

        const currentFilters = [...transportFilters, bikeTransportType, ...options].map((filter) => {
          return {
            ...filter,
            alone: filter.transport_type_id === 0 || filter.transport_type_id === 'network_map'
          }
        })

        dispatch(setCurrentFilters(currentFilters))
      } else {
        const others = [{ id: 0, show: false }, // Refill points
          { id: -1, show: !REACT_APP_SHOW_SUBWAY_AND_TRAIN_NETWORKS && true } // Users reports
        ]

        setLocalFiltersConfig(localFilters?.length >= 1
          ? [...localFilters, {
              filters: [...filters, ...others], city_id: cityConfig?.city_id
            }]
          : [{ filters: [...filters, ...others], city_id: cityConfig?.city_id }])

        const currentFilters = [...transportFilters, ...options].map((filter) => {
          return {
            ...filter,
            alone: filter.transport_type_id === 0 || filter.transport_type_id === 'network_map'
          }
        })

        dispatch(setCurrentFilters(currentFilters))
      }
    }
  }
}

export const setTripFilters = (cityConfig) => {
  return async (dispatch) => {
    let localTripFilters = localStorage.getItem('resultFilters')
      ? JSON.parse(localStorage.getItem('resultFilters'))
      : null

    let localCityFilters = localTripFilters?.find(filter => filter?.city_id.toString() === cityConfig?.city_id.toString())

    if (localCityFilters) {
      if (!localCityFilters?.transportation_types || localCityFilters?.transportation_types.length === 0) {
        localCityFilters = {
          ...localCityFilters,
          transportation_types: cityConfig?.transport_types?.map(type => ({
            ...type,
            checked: true
          }))
        }

        localTripFilters = localTripFilters?.map(filter => {
          if (filter.city_id === cityConfig.city_id) {
            filter.transportation_types = cityConfig?.transport_types?.map(type => ({
              ...type,
              checked: true
            }))
          }
          return filter
        })
      }

      setLocalResultFilters(localTripFilters)
      dispatch(setUserSearchFilters(localCityFilters))
    } else {
      const transportationTypes = cityConfig?.transport_types?.map(type => {
        return { ...type, checked: true }
      })

      const currentFilters = {
        type_of_route: 'best-route',
        city_id: cityConfig?.city_id,
        remember_settings: true,
        walks: 5,
        transportation_types: transportationTypes,
        radius: 500
      }

      if (localTripFilters && localTripFilters?.length > 0) {
        setLocalResultFilters([...localTripFilters, currentFilters])
      } else {
        setLocalResultFilters([currentFilters])
      }

      dispatch(setUserSearchFilters(currentFilters))
    }
  }
}

export const setCurrentCitySelected = (cityConfig) => {
  return (dispatch) => {
    localStorage.removeItem('resultFilters')
    localStorage.removeItem('filters')
    localStorage.removeItem('cityConfig')

    dispatch(setLines(undefined))
    dispatch(setAlerts(undefined))
    dispatch(setCityInfoList(undefined))

    const center = [cityConfig?.map?.map_center_lat, cityConfig?.map?.map_center_lng]

    const sw = new maplibregl.LngLat(cityConfig.map.map_southwest_lng, cityConfig.map.map_southwest_lat)
    const ne = new maplibregl.LngLat(cityConfig.map.map_northeast_lng, cityConfig.map.map_northeast_lat)

    const bounds = new maplibregl.LngLatBounds(sw, ne)

    const zoom = cityConfig?.map?.map_zoom_level

    dispatch(setViewState({
      latitude: cityConfig.map.map_center_lat,
      longitude: cityConfig.map.map_center_lng,
      zoom
    }))

    dispatch(setBounds(bounds))
    dispatch(setCenter(center))
    dispatch(setZoom(zoom))
    setLocalCurrentZoom(zoom)
    setLocalCurrentCenter(center)

    dispatch(setCurrentCityConfig(cityConfig))
    localStorage.setItem('cityConfig', JSON.stringify(cityConfig))
    setUserProperties({
      current_city: cityConfig?.name.toString(),
      current_city_id: cityConfig?.city_id.toString(),
      country: cityConfig?.country.name.toString(),
      country_id: cityConfig?.country.country_id.toString()
    })
    dispatch(setMapConfiguration())
    dispatch(setTripFilters(cityConfig))
    dispatch(verifyStop())
    dispatch(verifyAlerts())
    dispatch(verifyTripShare())
    dispatch(verifyCityInfo())
    dispatch(setFiltersConfig())
  }
}

export const setStopImage = (obj) => ({
  type: types.stopImage, payload: obj
})

export const getTripResults = () => {
  return async (dispatch, getState) => {
    const query = new URLSearchParams(window.location.search)

    const {
      user: { searchFilters: cityFilters, userPosition, auth, userData },
      ui: { places, cityConfig, dataTimeValue, selectLabel, mode },
      map: { markers }
    } = getState()

    const uid = auth?.uid

    const localCityFilters = getLocalResultFilters()

    let searchFilters = cityFilters

    if (!searchFilters) {
      if (cityFilters && cityFilters?.some(filters => filters?.city_id === cityConfig?.city_id)) {
        searchFilters = cityFilters?.find(filters => filters?.city_id === cityConfig?.city_id)
      } else if (localCityFilters && localCityFilters?.find(filters => filters?.city_id === cityConfig?.city_id)) {
        searchFilters = localCityFilters?.find(filters => filters?.city_id === cityConfig?.city_id)
      } else {
        searchFilters = {
          type_of_route: 'best-route',
          city_id: cityConfig?.city_id,
          remember_settings: true,
          walks: 5,
          transportation_types: cityConfig?.transport_types?.map(type => ({ ...type, checked: true })),
          radius: 500
        }
      }

      dispatch(setUserSearchFilters(searchFilters))
    }

    const currentTransports = searchFilters?.transportation_types?.filter(type => type.checked)

    const transports = currentTransports?.map(type => type.transport_type_id)?.toString()

    const currentTransfer = searchFilters?.type_of_route === 'only-directs' ? false : searchFilters?.type_of_route === 'only-transfers' ? true : null

    const reqBody = {
      city_id: cityConfig?.city_id?.toString() || query.get('ciudad_id'),
      lat_from: Number(places?.from?.latLng?.lat || query.get('latd')),
      lng_from: Number(places?.from?.latLng?.lng || query.get('lngd')),
      lat_to: Number(places?.to?.latLng?.lat || query.get('lath')),
      lng_to: Number(places?.to?.latLng?.lng || query.get('lngh')),
      radius: Number(searchFilters?.walks * 100 || query.get('radio')),
      transfer: currentTransfer || JSON.parse(query.get('transfer')),
      self_search: true,
      transport_types: transports || query.get('transport_types'),
      locale: 'es',
      lat_from_station: null,
      lng_from_station: null,
      lat_to_station: null,
      lng_to_station: null,
      arrive_by: selectLabel ? selectLabel !== i18next.t('search_preview.arrival_time_first_option') : query.get('arrive_by') === 'true',
      timestamp: dataTimeValue || JSON.parse(query.get('timestamp'))
    }

    let closestFromBikeStation
    let closestToBikeStation

    const cityHasRentedBikeTransport = cityConfig?.transport_types?.some(transport => parseInt(transport.transport_type_id) === 5)

    if (cityConfig?.config?.bike_networks && cityHasRentedBikeTransport) {
      let stations = markers?.bikeStations?.stations || []

      if (markers?.bikeStations?.stations?.length === 0) {
        try {
          const bikeStations = await getBikeNetworksByCity(cityConfig?.config?.bike_networks)
          dispatch(setBikeStations({ enabled: true, stations: bikeStations?.network?.stations }))
          stations = bikeStations?.network?.stations
        } catch (e) {
          console.error(e)
          stations = []
        }
      }

      if (stations?.length >= 1) {
        const radio = parseInt(query.get('radio'))
        const fromPoint = places?.from?.latLng || {
          lat: Number(query.get('latd')),
          lng: Number(query.get('lngd'))
        }
        const toPoint = places?.to?.latLng || { lat: Number(query.get('lath')), lng: Number(query.get('lngh')) }

        closestFromBikeStation = getClosetsBikeStation(fromPoint, radio, stations, 'free_bikes')
        closestToBikeStation = getClosetsBikeStation(toPoint, radio, stations, 'empty_slots')

        if (closestFromBikeStation && closestToBikeStation) {
          reqBody.lat_from_station = closestFromBikeStation.latitude
          reqBody.lng_from_station = closestFromBikeStation.longitude
          reqBody.lat_to_station = closestToBikeStation.latitude
          reqBody.lng_to_station = closestToBikeStation.longitude
        }
      }
    }

    let tripResponse

    try {
      const colors = ['#FF5252', '#00dede', '#FF8B1D', '#1D91FF', '#4FB0C6', '#C6654F', '#149911', '#961199']

      tripResponse = await getTrips(reqBody)

      tripResponse.itineraries.forEach((trip) => {
        if (trip.transfers > 0) {
          const legsNoWalk = trip.legs.filter(leg => leg.mode !== 'WALK')

          legsNoWalk?.forEach(leg => {
            if (leg?.routeColor === legsNoWalk[0]?.routeColor && REACT_APP_CHANGE_ROUTE_COLOR_ON_TRANSFERS) {
              legsNoWalk[1].routeColor = getBestContrastColor(`#${legsNoWalk[0]?.routeColor}`, colors).slice(1)
            }
          })
        }
      })

      dispatch(setTripResponse(tripResponse))

      if (searchFilters) {
        // Verify that the bike filter is enabled in the search filters
        const bikes = searchFilters?.transportation_types?.some(filter => filter?.checked && filter?.transport_type_id === 17)

        // Verify that some public transport filter is enabled in the search filters
        const transport = searchFilters?.transportation_types?.some(filter => filter?.checked && filter?.transport_type_id !== 17)

        if (bikes) { // Event is sent when performing a search with the selected bike filter
          logEvent('check_bike_routes', {
            origin_latitude: Number(query.get('latd')),
            origin_longitude: Number(query.get('lngd')),
            destination_latitude: Number(query.get('lath')),
            destination_longitude: Number(query.get('lngh')),
            lat: userPosition ? userPosition.lat : null,
            lng: userPosition ? userPosition.lng : null,
            user_id: uid,
            os: 'web',
            city_id: cityConfig?.city_id.toString()
          })
        }

        if (transport) { // Event is sent when performing a search with a selected public transport filter
          logEvent('check_bus_routes', {
            origin_latitude: Number(query?.get('latd')), // Double
            origin_longitude: Number(query?.get('lngd')), // Double
            destination_latitude: Number(query?.get('lath')), // Double
            destination_longitude: Number(query?.get('lngh')), // Double
            radius: Number(query?.get('radio')), // Double ?
            results_found: tripResponse?.itineraries?.length, // Int
            taxi_remis_results_found: tripResponse?.itinerariesCar?.length >= 1 ? tripResponse?.itinerariesCar?.length : null, // Int
            search_uuid: uid + dataTimeValue, // String
            lat: userPosition ? userPosition?.lat : null, // Double
            lng: userPosition ? userPosition?.lng : null, // Double
            user_id: uid, // String
            os: 'web', // String
            city_id: query?.get('ciudad_id'), // String
            user_birthday_timestamp: userData ? userData?.birthday?.long_value : null, // Long
            user_gender: userData ? userData?.gender : null, // String
            meters: tripResponse?.itinerariesCar?.legs?.length === 1 ? tripResponse?.itinerariesCar?.legs[0]?.distance : null, // Float
            transport_types: query.get('transport_types') // String
          })
        }
      }
    } catch (e) {
      console.error('Error getting current trip search', e)
    }

    if (!mode && !window.location.pathname.includes('trip_share')) {
      dispatch(setOpenTripResults(true))
    }

    if (!tripResponse) return

    try {
      // check if tripResponse.itinerariesBike is an empty object (objectKeys)

      if (Object.keys(tripResponse?.itinerariesBike)?.length === 0) return

      const fromWalkLeg = await getGraphhopperRoute({
        city_id: cityConfig?.city_id,
        points: [
          [places?.from?.latLng?.lng || Number(query.get('lngd')), places?.from?.latLng?.lat || Number(query.get('latd'))],
          [closestFromBikeStation?.longitude, closestFromBikeStation?.latitude]
        ],
        elevation: false,
        profile: 'foot',
        locale: 'es'
      })

      const toWalkLeg = await getGraphhopperRoute({
        city_id: cityConfig?.city_id,
        points: [
          [closestToBikeStation?.longitude, closestToBikeStation?.latitude],
          [places?.to?.latLng?.lng || Number(query.get('lngh')), places?.to?.latLng?.lat || Number(query.get('lath'))]
        ],
        elevation: false,
        profile: 'foot',
        locale: 'es'
      })

      if (!fromWalkLeg || !toWalkLeg) {
        dispatch(setTripResponse({
          ...tripResponse,
          itinerariesBike: {}
        }))
        return
      }

      const fromWalkPoints = polyUtil.decode(fromWalkLeg.paths[0].points)
      const toWalkPoints = polyUtil.decode(toWalkLeg.paths[0].points)

      if (fromWalkPoints?.length > 0 && toWalkPoints?.length > 0) {
        dispatch(setTripResponse({
          ...tripResponse,
          itinerariesBike: {
            ...tripResponse.itinerariesBike,
            fromBikeStation: closestFromBikeStation,
            toBikeStation: closestToBikeStation,
            legs: [
              {
                ...fromWalkLeg?.paths[0],
                mode: 'WALK',
                from: {
                  lat: fromWalkPoints[0][0],
                  lon: fromWalkPoints[0][1]
                },
                to: {
                  lat: fromWalkPoints[fromWalkPoints?.length - 1][0],
                  lon: fromWalkPoints[fromWalkPoints?.length - 1][1]
                }
              },
              ...tripResponse.itinerariesBike.legs,
              {
                ...toWalkLeg?.paths[0],
                mode: 'WALK',
                from: {
                  lat: toWalkPoints[0][0],
                  lon: toWalkPoints[0][1]
                },
                to: {
                  lat: toWalkPoints[toWalkPoints?.length - 1][0],
                  lon: toWalkPoints[toWalkPoints?.length - 1][1]
                }
              }
            ]
          }
        }))
      }
    } catch (e) {
      console.error(e)
    }
  }
}

/**
 *
 * @param {boolean} open
 * @returns {(function(*, *): void)|*}
 */

export const setOpenCityChange = (open) => {
  return (dispatch, getState) => {
    const { user: { userPosition, auth, userData }, ui: { cityConfig, latam } } = getState()

    if (open) {
      const eventParams = {
        lat: userPosition ? userPosition.lat : null, // Double
        lng: userPosition ? userPosition.lng : null, // Double
        user_id: auth.uid, // String
        city_id: cityConfig?.city_id.toString(), // String
        city_name: cityConfig?.name, // String
        os: 'web', // String
        user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
        user_gender: userData?.gender || null, // String
        from: null // String
      }

      logEvent('change_city_button', eventParams)

      if (latam?.countries?.length === 0) {
        getAllCountries()
          .then((countries) => {
            dispatch(setCountries(countries))
            dispatch(setOpenCountries(true))
          })
      } else {
        dispatch(setOpenCountries(true))
      }
    } else {
      dispatch(setCountrySelected(undefined))
    }

    dispatch(setOpenMenu(false))
  }
}

export const handleCloseMenu = () => {
  return (dispatch) => {
    dispatch(setShowMenuNotification(false))
    dispatch(setOpenMenu(false))
  }
}

export const handleOpenMenu = () => {
  return (dispatch, getState) => {
    const {
      user: { userPosition, auth },
      ui: { cityConfig }
    } = getState()

    localStorage.setItem('showMenuNotification', JSON.stringify(false))

    const eventParams = {
      lat: userPosition?.lat ? userPosition.lat : null,
      lng: userPosition?.lng ? userPosition.lng : null,
      user_id: auth?.uid,
      city_id: cityConfig?.city_id?.toString()
    }

    logEvent('drawer_menu_open', eventParams)

    dispatch(setOpenMenu(true))
  }
}

export const setIsIosApp = (boolean) => ({
  type: types.isIosApp, payload: boolean
})

/** setShowMenuNotification()
 * @description Sets showMenuNotification value in redux store.
 * if value is true, shows a badge in the menu icon (mobile and desktop)
 * @param {boolean} boolean
 * @returns {{payload, type: string}}
 */

export const setShowMenuNotification = (boolean) => ({
  type: types.showMenuNotification, payload: boolean
})

setShowMenuNotification.prototype = {
  boolean: PropTypes.bool.isRequired
}

/** setOpenSendCommentariesDialog()
 * @description Sets openSendCommentariesDialog value in redux store.
 * If value is true, opens a dialog that shows an input to send a commentary like suggestion
 * @param {boolean} boolean
 * @returns {{payload, type: string}}
 */

export const setOpenSendCommentariesDialog = (boolean) => ({
  type: types.openSendCommentariesDialog, payload: boolean
})

setOpenSendCommentariesDialog.prototype = {
  boolean: PropTypes.bool.isRequired
}

/** handleRecharge()
 * @description When the user clicks on the recharge button:
 * If the user is logged, it redirects to the recharge page (marketplace) but,
 * if the user is not logged in, it opens a dialog to invite the user to log in
 */

export const handleRecharge = () => {
  return async (dispatch, getState) => {
    const { ui: { cityConfig, mode } } = getState()

    const token = await getCustomToken()
    window.open(`${cityConfig?.card_types[0]?.recharge_url}&custom_token=${token}#recarga-online`, mode ? '_self' : '_blank')
  }
}

/** handleOpenAlert()
 * Open the current alert and sets the current state of an alert globally
 * @param {string} title
 * @param {string} message
 * @param {string} severity
 * @param {number} duration
 * @returns {{payload: *, type: string}}
 */

export const handleOpenAlert = ({
  title = '', message = '', severity = 'success', duration = 3000
}) => ({
  type: types.alert, payload: { open: true, title, message, severity, duration }
})

handleOpenAlert.prototype = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
  duration: PropTypes.number
}

/** handleCloseAlert();
 * Closes the current alert and reset its global values to their initial state
 * @returns {{payload: {severity: string, duration: number, title: string, message: string, open: boolean}, type: string}}
 */

export const handleCloseAlert = () => ({
  type: types.alert, payload: { open: false, title: '', message: '', severity: 'success', duration: 3000 }
})

/** setOpenSmartBanner()
 * Closes smart banner full page
 * @param {boolean} boolean - It only can be true or false
 * @returns {{payload, type: string}}
 */

export const setOpenSmartBanner = (boolean) => ({
  type: types.openSmartBanner, payload: boolean
})

setOpenSmartBanner.prototype = {
  boolean: PropTypes.bool.isRequired
}

/** setOpenBottomSmartBanner()
 * Closes smart bottom banner
 * @param boolean
 * @returns {{payload, type: string}}
 */

export const setOpenBottomSmartBanner = (boolean) => ({
  type: types.openBottomSmartBanner, payload: boolean
})

setOpenBottomSmartBanner.prototype = {
  boolean: PropTypes.bool.isRequired
}

/** setOpenTopSmartBanner()
 * Closes smart top banner
 * @param boolean
 * @returns {{payload, type: string}}
 */

export const setOpenTopSmartBanner = (boolean) => ({
  type: types.openTopSmartBanner, payload: boolean
})

setOpenTopSmartBanner.prototype = {
  boolean: PropTypes.bool.isRequired
}

export const setCityAds = (response) => ({
  type: types.ads,
  payload: {
    searchResult: response?.ads.filter(item => item.ad_type_id === 20).map(ad => ({ ...ad, loading: true })),
    rotationTimes: response?.rotationTime
  }
})

export const setAdsList = (adsUpdated) => ({
  type: types.setAdList, payload: adsUpdated
})

export const setAdSelected = (adSelected) => ({
  type: types.adSelected, payload: adSelected
})

export const setOpenAdSelected = (boolean) => ({
  type: types.openAdSelected, payload: boolean
})

export const handleClickAd = (adSelected) => {
  return (dispatch, getState) => {
    const {
      ui: { cityConfig }, user: { userPosition, userData, auth }
    } = getState()

    logEvent('custom_ad_marker_clicked', {
      user_id: auth?.uid,
      os: 'web',
      city_id: cityConfig?.city_id.toString(),
      lat: userPosition?.lat ? userPosition.lat : null,
      lng: userPosition?.lng ? userPosition.lng : null,
      user_gender: userData?.gender ? userData.gender : null,
      user_birthday_timestamp: userData?.birthday?.long_value ? userData.birthday.long_value : null,
      ad_id: adSelected?.ad_id,
      ad_marker_id: adSelected?.marker?.id,
      ad_category: adSelected?.categories_key,
      ad_subcategory: adSelected?.subcategories_key
    })

    dispatch(setAdSelected(adSelected))
    dispatch(setOpenAdSelected(true))
  }
}

export const handleCloseAd = () => {
  return (dispatch) => {
    dispatch(setAdSelected(null))
    dispatch(setOpenAdSelected(false))
  }
}

/** setShowRouteExtended()
 * @param {boolean} boolean
 * @returns {{payload, type: string}}
 */

export const setShowRouteExtended = (boolean) => ({
  type: types.showRouteExtended,
  payload: boolean
})

setShowRouteExtended.prototype = {
  boolean: PropTypes.bool.isRequired
}

/** getVehiclePositions()
 * Gets current vehicle positions by current city id
 * @param {string} routeId
 * @param {number} directionId
 * @param {string} routeType
 * @param {string} routeName
 * @returns {(function(*, *): void)|*}
 */

export const getVehiclePositions = (routeId, directionId = 0, routeType, routeName, agencyId) => {
  return (dispatch, getState) => {
    const {
      ui: { cityConfig }
    } = getState()

    const localCityConfig = getLocalCityConfig()

    if (!cityConfig && !localCityConfig) {
      console.error('cityConfig not funded')
      return
    }

    if (!routeId) {
      console.error('routeId selected not found')
      return
    }

    const cityId = getCurrentCityId(cityConfig)

    if (cityConfig?.config.realtime_hub_enabled || localCityConfig?.config.realtime_hub_enabled) {
      const params = {
        city_id: cityId,
        route_id: routeId, // routeSelected?.route_id,
        direction_id: directionId // routeSelected?.direction_id
      }

      getVehiclePositionsByRoute(params)
        .then(response => {
          const result = response?.result?.map(vehicle => {
            return {
              id: vehicle?.vehicle?.vehicle?.id,
              coordinates: [vehicle?.vehicle?.position.longitude, vehicle?.vehicle?.position.latitude],
              properties: vehicle?.proximaParada || null,
              timestamp: vehicle?.vehicle?.timestamp
            }
          })

          dispatch(setTripSelectedVehicles(result))
        })
        .catch((e) => {
          console.error(e)
        })
    } else if (cityConfig?.config.realtime_arrivals_enabled || localCityConfig?.config.realtime_arrivals_enabled) {
      getVehiclesOfThirdParties(cityId, routeType, routeName, directionId, agencyId)
    }
  }
}

const getCurrentCityId = (cityConfig) => {
  const pathname = window.location.pathname
  const localCityConfig = getLocalCityConfig()

  if (pathname.split('/')[1] === 'routes') {
    return pathname.split('/')[2]
  } else if (cityConfig?.city_id) {
    return cityConfig?.city_id || localCityConfig?.city_id
  }
}

/** getVehiclesOfThirdParties
 * Gets current vehicle positions by current city id from third parties
 * @param {number} cityId
 * @param {string} routeType
 * @param {string} routeName
 * @param {number} directionId
 * @param {string} agencyId
 * @returns {(function(*): void)|*}
 */

const getVehiclesOfThirdParties = (cityId, routeType, routeName, directionId, agencyId) => {
  return (dispatch) => {
    switch (cityId) {
      case 21: { // Buenos Aires, Argentina
        if (routeType === 3) { // Bus
          const params = {
            city_id: cityId,
            url: 'https://apitransporte.buenosaires.gob.ar/colectivos/vehiclePositionsSimple?agency_id=' + agencyId,
            api_buenos_aires: true,
            method: 'GET'
          }

          getArrivalsRealTime(params)
            .then((response) => {
              const vehiclesWidthRealtime = response?.filter(vehicle => routeName === vehicle.route_short_name && directionId === vehicle.direction)
                .map(vehicle => {
                  return {
                    coordinates: [vehicle?.longitude, vehicle?.latitude],
                    properties: vehicle?.trip_headsign,
                    timestamp: vehicle?.timestamp
                  }
                })

              dispatch(setTripSelectedVehicles(vehiclesWidthRealtime))
            })
            .catch((err) => {
              console.error(err)
            })
        }
        break
      }
      case 30: { // Montevideo, Uruguay
        const params = {
          city_id: cityId,
          url: 'https://www.montevideo.gub.uy/buses/rest/stm-online',
          body: {
            empresa: '-1',
            lineas: [routeName]
          },
          method: 'POST'
        }

        getArrivalsRealTime(params)
          .then(response => {
            const vehiclesWidthRealtime = response?.result?.features?.filter(vehicle => routeName === vehicle.properties.linea)
              .map(vehicle => {
                return {
                  coordinates: vehicle.geometry.coordinates,
                  properties: vehicle.properties
                }
              })

            dispatch(setTripSelectedVehicles(vehiclesWidthRealtime))
          })
          .catch((err) => {
            console.error(err)
          })
        break
      }
    }
  }
}

export const setShowETA = (boolean) => ({
  type: types.showETA,
  payload: boolean
})

export const setArrivalSelected = (arrivalSelected) => ({
  type: types.arrivalSelected,
  payload: arrivalSelected
})

export const resetPlacesSelected = () => {
  return (dispatch, getState) => {
    const { ui: { cityConfig }, user: { userPosition } } = getState()

    const sw = new maplibregl.LngLat(cityConfig.map.map_southwest_lng, cityConfig.map.map_southwest_lat)
    const ne = new maplibregl.LngLat(cityConfig.map.map_northeast_lng, cityConfig.map.map_northeast_lat)
    const newBounds = new maplibregl.LngLatBounds(sw, ne)

    let from

    const to = {
      name: i18next.t('hint_location_B'),
      latLng: { lat: cityConfig.map.destination_marker_lat, lng: cityConfig.map.destination_marker_lng }
    }

    if (userPosition && newBounds?.contains(new maplibregl.LngLat(userPosition?.lng, userPosition?.lat))) {
      from = { name: i18next.t('my_location'), latLng: userPosition }
    } else {
      from = {
        name: i18next.t('hint_location_A'),
        latLng: {
          lat: cityConfig?.map?.origin_marker_lat, lng: cityConfig?.map?.origin_marker_lng
        }
      }
    }

    dispatch(setFromPlacesSelected(from))

    dispatch(setToPlacesSelected(to))
  }
}

export const setCurrentLines = (currentLines) => {
  let lines = currentLines
  lines = _.orderBy(lines, ['route_short_name'], ['asc'])
  lines = _.orderBy(lines, ['route_long_name'], ['asc'])
  lines = _.orderBy(lines, ['fav'], ['desc'])
  return {
    type: types.currentLines,
    payload: lines
  }
}

export const setLinesByAgency = (transportId = 0, currentLines = []) => {
  let linesGrouped = currentLines.filter(line => !line?.fav)

  if (transportId !== 0) {
    linesGrouped = currentLines.filter(line => line.transport_type.transport_type_id === transportId)
  }

  linesGrouped = _.groupBy(linesGrouped, transportId === 0 ? 'transport_type_id' : 'agency_id')

  const linesByAgency = Object?.keys(linesGrouped)?.map((key) => {
    let linesOrdered = linesGrouped[key]?.filter(line => !line?.fav)
    linesOrdered = _.orderBy(linesOrdered, ['route_long_name'], ['asc'])
    linesOrdered = _.orderBy(linesOrdered, ['fav'], ['desc'])

    return {
      name: transportId === 0 ? linesGrouped[key][0]?.transport_type?.name : linesGrouped[key][0]?.agency?.agency_name,
      lines: linesOrdered
    }
  })

  return {
    type: types.currentLinesByAgency,
    payload: _.orderBy(linesByAgency, ['name'], ['asc'])
  }
}

export const setAutocompleteStations = (stations) => {
  return {
    type: types.autoCompleteStations,
    payload: stations
  }
}

export const setSearchResultClickCount = () => {
  return {
    type: types.searchResultClickCount
  }
}

export const setNPSSurveyShown = (boolean) => {
  return {
    type: types.NPSSurveyShown,
    payload: boolean
  }
}
