import PropTypes from 'prop-types'
import { getAuth } from 'firebase/auth'
import { REACT_APP_API_FEED } from '../constants/config'
export const getVehiclePositionsByRoute = async (params) => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  if (isNaN(parseInt(params.city_id))) return

  const reqBody = {
    route_id: params?.route_id,
    direction_id: params?.direction_id
  }

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'variant-id': process.env.REACT_APP_VARIANT_ID
    },
    body: JSON.stringify(reqBody)
  }

  return (
    fetch(`${REACT_APP_API_FEED}/${params?.city_id}/realtimeFeed/vehiclePositionsByRoute`, reqConfig)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Error getting vehicle positions by route')
        }
      })
      .then((response) => response)
  )
}

getVehiclePositionsByRoute.propTypes = {
  city_id: PropTypes.string,
  route_id: PropTypes.string,
  direction_id: PropTypes.string
}
