import React from 'react'
import { FontProvider } from './contexts/FontContext'
import { AppRouter } from './router/AppRouter'
import { MapProvider } from 'react-map-gl'
import AppGlobalStyle from './AppGlobalStyles'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { theme } from './theme/theme'

export const App = () => {
  return (
    <FontProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppGlobalStyle />
        <MapProvider>
          <AppRouter />
        </MapProvider>
      </ThemeProvider>
    </FontProvider>
  )
}
