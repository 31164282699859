import React from 'react'
import { RecommendedRoutesContainer } from '../../../GlobalStyles'
import ResultItem from '../../ResultItem'
import { useTripResults } from '../../../hooks/useTripResults'
import {
  ShowLessIcon,
  ShowMoreContainer,
  ShowMoreIcon,
  ShowMoreText
} from '../../../components/SearchResults/styles'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import NoResultMessage from '../../NoResultMessage'

export const RecommendedRoutes = ({
  showMore,
  setShowMore,
  itineraries,
  setItineraries
}) => {
  const { t } = useTranslation()

  const {
    handleTripClick
  } = useTripResults()

  const loading = useSelector(state => state?.ui?.loading)
  const tripResponse = useSelector(state => state?.ui?.tripResponse)

  const handleShowMore = () => {
    setShowMore(!showMore)

    if (!showMore) {
      setItineraries(tripResponse?.itineraries)
    } else {
      window.scrollTo(0, 0)
      setItineraries(tripResponse?.itineraries?.slice(0, 3))
    }
  }

  return (
    <RecommendedRoutesContainer>
      {tripResponse
        ? (
          <>
            {tripResponse?.itineraries?.length === 0
              ? (
                <NoResultMessage message={t('trip_share.could_not_find_results')} />
                )
              : (
                <>
                  {itineraries?.map((trip, index) => {
                    const currentTip = trip?.legs?.find(leg => leg?.mode !== 'WALK')

                    return (
                      <ResultItem
                        disabled={loading}
                        key={index}
                        trip={trip}
                        onClickFunction={handleTripClick}
                        border
                        icons={false}
                        showOccupancyLevel={currentTip?.crowdedAverage > 0}
                      />
                    )
                  })}

                  {tripResponse?.itineraries?.length > 3 &&
                    <ShowMoreContainer
                      sx={{
                        width: '100%',
                        textTransform: 'none',
                        height: '48px'
                      }}
                      onClick={handleShowMore}
                    >
                      <ShowMoreText>  {showMore ? t('trip_share.button_load_fewer_search_results') : t('trip_share.button_load_more_search_result')}
                      </ShowMoreText>
                      {showMore ? <ShowLessIcon /> : <ShowMoreIcon />}
                    </ShowMoreContainer>}
                </>
                )}
          </>
          )
        : (
            [0, 1, 2].map((skeleton, index) => (
              <ResultItem
                disabled
                key={index}
                trip={null}
                border
                icons={false}
                showOccupancyLevel={false}
              />
            ))
          )}

    </RecommendedRoutesContainer>
  )
}
