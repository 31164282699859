import React from 'react'
import phoneImage from '../../../../img/smart_banner_bottom_phone.png'
import { Box, Chip, IconButton, Typography } from '@mui/material'
import { getValue } from 'firebase/remote-config'
import { logEvent, remoteConfig } from '../../../../firebase/firebase-config'
import { CloseRounded } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenBottomSmartBanner } from '../../../../actions/ui'
import { getLocalShowPrompt } from '../../../../db/localStorage/getLocalShowPrompt'
import { useTranslation } from 'react-i18next'

/** <SmartBottomBanner/>
 * @description The component is displayed at the bottom of the home screen on mobile when the openBottomSmartBanner redux value is true. This means it is not a white label version, not an iframe, not embedded in the ios version and the value of the "show_smart_banner_bottom" parameter is true.
 *
 * Initial state:
 *     - openBottomSmartBanner: It can be: true or false.
 *                              If it is not a white label version, it is not an iframe, it is not embedded in the ios version and the value of the parameter "show_smart_banner_bottom" is true, otherwise the value is false
 *
 * Methods:
 *     - handleOpenApp(): The link is fetched from the remote config and passed as a parameter to the getDynamicLinks
 *                        function, the dynamic link is fetched and opened to redirect the user to the app or open the store for download.
 *                        Sends "smart_banner_bottom_cta" event to Analytics.
 *
 *      - handleClose():   Sends "close_smart_banner_bottom_cta" event to Analytics and closes bottom smart banner
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const SmartBottomBanner = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const user = useSelector(state => state?.user?.auth)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const userData = useSelector(state => state?.user?.userData)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  /** handleOpenApp()
   * @description The link is fetched from the remote config and passed as a parameter to the getDynamicLinks
   * function, the dynamic link is fetched and opened to redirect the user to the app or open the store for download.
   * Sends "smart_banner_bottom_cta" event to Analytics.
   * @returns {Promise<void>}
   */

  const handleOpenApp = async () => {
    try {
      /* const {_value: link} = getValue(remoteConfig, "smart_banner_bottom_cta_url"); */
      const { _value: ctaUrl } = getValue(remoteConfig, 'smart_banner_bottom_cta_url')

      /* const parmas = {
                link,
                title: '¡Descarga la app!',
                description: 'Elige entre las rutas y horarios de transporte público, alternativas de micromovilidad y servicios de taxis o autos geolocalizados',
                picture: downloadApp
            } */

      logEvent('smart_banner_bottom_cta', {
        lat: userPosition?.lat ? userPosition?.lat : null, // Double or null
        lng: userPosition?.lng ? userPosition?.lng : null, // Double or null
        user_gender: userData?.gender ? userData?.gender : null, // String or null
        user_birthday_timestamp: userData?.birthday?.long_value ? userData?.birthday?.long_value : null, // Long or null
        os: 'web', // String
        user_id: user?.uid, // String
        city_id: cityConfig?.city_id?.toString(),
        url: ctaUrl // String
      })

      // Gets dynamic link
      /* const {shortLink} = await getDynamicLinks(parmas); */

      // Open dynamic link
      window.open(ctaUrl, '_self')
    } catch (e) {
      console.error('Error getting link', e)
    }

    // setOpenBottomSmartBanner redux action is called and openBottomSmartBanner value is changed to false. This causes the component to stop displaying.
    dispatch(setOpenBottomSmartBanner(false))
  }

  /** handleClose()
   * @description Sends "close_smart_banner_bottom_cta" event to Analytics and closes bottom smart banner
   */

  const handleClose = () => {
    const count = getLocalShowPrompt()

    if (count < 3) {
      localStorage.setItem('showPrompt', count + 1)
    }

    // Sends 'close_smart_banner_bottom_cta' event to Analytics
    logEvent('close_smart_banner_bottom_cta', {
      lat: userPosition?.lat ? userPosition?.lat : null, // Double or null
      lng: userPosition?.lng ? userPosition?.lng : null, // Double or null
      user_gender: userData?.gender ? userData?.gender : null, // String or null
      user_birthday_timestamp: userData?.birthday?.long_value ? userData?.birthday?.long_value : null, // Long or null
      os: 'web', // String
      user_id: user?.uid, // String
      city_id: cityConfig?.city_id?.toString()
    })

    // setOpenBottomSmartBanner redux action is called and openBottomSmartBanner value is changed to false. This causes the component to stop displaying.
    dispatch(setOpenBottomSmartBanner(false))
  }

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        position: 'relative'
      }}
    >
      <img
        alt='Phone image'
        src={phoneImage}
        style={{
          width: '120px',
          position: 'absolute',
          bottom: 0,
          left: '8px',
          zIndex: 401
        }}
      />
      <Box
        sx={{
          width: 'calc(100% - 128px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: '20px 16px 16px 16px',
          boxSizing: 'border-box',
          position: 'relative'
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            backgroundColor: '#fff',
            top: -16,
            right: 10,
            zIndex: 401,
            '&:hover': {
              backgroundColor: '#fff'
            }
          }}
          onClick={handleClose}
        >
          <CloseRounded />
        </IconButton>

        <Typography
          variant='h2'
          sx={{
            color: '#909397',
            fontSize: '14px',
            margin: 0,
            textAlign: 'center'
          }}
        >
          {t('smart_banners.bottom.title')}
        </Typography>
        <Typography
          variant='h3'
          sx={{
            color: '#909397',
            fontSize: '12px',
            margin: '10px 0',
            textAlign: 'center'
          }}
        >
          {t('smart_banners.bottom.subtitle')}
        </Typography>
        <Chip
          sx={{
            backgroundColor: '#3e9e98',
            color: '#fff',
            fontSize: 14,
            padding: '0 2px',
            '&:hover': {
              backgroundColor: '#3e9e98'
            }
          }}
          label={t('smart_banners.bottom.use_app')}
          clickable
          onClick={handleOpenApp}
        />
      </Box>
    </Box>
  )
}
